.invite-page
  .delivery-page-payment
    background-color: #F6F6F6
    padding: 16px 0 20px 0
  .breadcrumbs-area
    background-color: #fff
  .breadcrumbs__link
    color: #1E2225 !important
  h2
    color: #00408D

.invite-page-form-area
  background-color: #6D6F7C
  padding: 30px 0

.form-invite-area
  &__button-area
    display: flex
    justify-content: center
    width: 100%
  &__button-red
    padding: 10px 65px
    border-radius: 50px
    max-width: 300px
    @media all and (max-width: 575.8px)
      padding: 10px 20px
    span
      text-transform: uppercase
      font: 700 16px/22px 'Open Sans', sans-serif

.form-invite
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.15)
  background-color: #ffffff
  padding: 13px 20px 28px 20px
  border-radius: 5px
  margin-bottom: 33px
  &__title
    display: block
    color: #E86200
    font: 700 24px/130% 'Open Sans', sans-serif
    margin-bottom: 30px
    text-align: center
    text-transform: uppercase
  &__fields
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-template-rows: repeat(3, 1fr)
    gap: 15px
    @media all and (max-width: 575.8px)
      grid-template-columns: repeat(1, 1fr)
    .form-order__form
      margin-bottom: 0
  .form-check
    margin-top: 10px

.col-mod
  width: 53%

.col-mod-2
  width: 47%

.agreement
  cursor: pointer

.form-invite-info
  padding-left: 24px
  margin-bottom: 20px
  padding-top: 12px
  padding-right: 20px
  @media all and (max-width: 575.8px)
    padding-left: 0
    padding-top: 0
  &__title
    margin-bottom: 25px
    font: 700 24px/130% 'Open Sans', sans-serif
    color: #1A2939
    text-transform: uppercase
  &__item
    display: flex
    align-items: center
    margin-bottom: 15px
    &:last-child
      margin-bottom: 0
  &__item-image
    margin-right: 13px
  &__item-text
    color: #232C36
    font: 600 18px/130% 'Open Sans', sans-serif

.invite-page-info
  padding: 50px 0 28px 0

.invite-page-text-area
  background: #5d5e69
  padding: 35px 0

.invite-page-text
  font: 400 18px/27px 'Open Sans', sans-serif
  color: #FFFFFF
  span
    color: #FFCAA4
    font-weight: 700

.invite-page-text-mod
  font: 400 18px/25px 'Open Sans', sans-serif
  color: #040404
  span
    color: #040404
    font-weight: 700

.invite-steps-area
  background-color: #6D6F7C
  padding: 40px 0 12px 0

.invite-page-detail
  padding: 30px 0 0 0
  position: relative
  &__image-block
    position: relative
    &::after
      content: ''
      width: 100%
      height: 25px
      position: absolute
      left: 0
      bottom: 0
      background-image: url("../../assets/images/invite-line.png")
  .company-product-item__image
    position: relative
    transform: none
    left: auto
    height: 280px
    margin-bottom: 35px
  .company-product-item
    padding-top: 0
  //flex: 1 1 auto
  &__image
    position: relative
    width: 100%
    height: 345px
    @media all and (min-width: 1920px)
      //height: 490px
    @media all and (max-width: 991.9px)
      display: none
  &__list
    padding: 23px 0px 40px 0px
    z-index: 1
    position: relative
    @media all and (max-width: 991.9px)
      padding-top: 25px
  &__button-area
    margin-top: 23px
    display: flex
    justify-content: center
  &__button-blue
    max-width: 300px
    width: 100%
    padding: 10px
    span
      text-transform: uppercase
      font: 700 16px/22px "Open Sans", sans-serif

.invite-page-about
  padding: 40px 0 0 0
  &__title
    position: relative
  &__info
    background-color: #F6F6F6
    padding: 0px 0 10px 0
  &__button-area
    display: flex
    justify-content: center
    flex-wrap: wrap
  &__button-white span
    color: #444550 !important
  .button
    margin: 0 20px 20px 20px
    max-width: 325px
    width: 100%
    padding: 10px 15px
    border-radius: 50px
    text-align: center
    span
      color: #fff
      font: 700 17px/23px "Open Sans", sans-serif
    &:focus
      span
        color: #fff !important
    &:active, &:hover
      span
        color: #fff !important

.invite-steps
  margin-bottom: 20px
  //margin-top: 35px
  display: flex
  background-color: #ffffff
  border-radius: 5px
  padding: 35px 10px 27px 10px
  @media all and (max-width: 991.9px)
    flex-wrap: wrap
    flex-direction: column
  &__col
    padding: 0 10px
    width: 315px
    flex: 0 0 315px
    @media all and (max-width: 991.9px)
      order: 3
      width: fit-content
      margin: 0 auto
      padding: 0
    &:first-child, &:last-child
      padding-top: 25px
      flex: 1 1 auto
      @media all and (max-width: 991.9px)
        order: 1
        width: 100%
  &__col-middle
    width: 100%
    max-width: 360px
    flex: 0 1 360px
    padding: 0

.invite-step
  display: flex
  align-items: center
  letter-spacing: -0.7px
  width: 100%
  position: relative
  min-height: 95px
  border: 1px solid #E5E5E5
  border-radius: 5px
  margin-bottom: 47.5px
  font: 600 16px/21px "Open Sans", sans-serif
  padding: 25px 10px 25px 20px
  background-color: #ffffff
  @media all and (max-width: 991.9px)
    margin-bottom: 30px
  &:last-child
    margin-bottom: 0
  &:before
    display: flex
    justify-content: center
    width: 55px
    background-color: #fff
    content: attr(data-count)
    position: absolute
    left: 50%
    top: -45%
    font: 600 44px/40px "Open Sans", sans-serif
    font-style: italic
    color: #E86200
    transform: translate(-50%, 50%)

.invite-sert
  padding: 10px
  background: #FFFFFF
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15)
  border-radius: 5px
  position: relative
  display: flex
  justify-content: center
  @media all and (max-width: 991.9px)
    margin-top: 30px

.invite-page-geo
  padding-top: 36px
  &__map
    width: auto
    max-height: 550px
    height: auto

.geo-fancy
  display: flex
  justify-content: center
  background-color: #F6F6F6
  margin-top: 29px

.geo-work
  padding-top: 31px
  //padding-bottom: 40px
  &__text
    margin-bottom: 20px
    width: 100%
    text-align: center
    font: 600 21px/150% "Open Sans", sans-serif
    color: #040404
    span
      color: #E86200
  &__list
    margin: 0 -4px
    flex-wrap: wrap
    display: flex
    justify-content: center
  &__image
    margin: 0 4px 13px 4px

.invite
  position: fixed
  top: 130px
  right: 0
  //width: 62px
  //height: 174px
  z-index: 100
  @media all and (max-width: 991.9px)
    top: 240px
//width: 45px
//height: 162px

.form-invite-area
  padding: 30px
  background-color: #F6F6F6
  border-radius: 10px
//margin: 0 10px

.invite-page-list-area
  background-color: #F6F6F6

@media all and (max-width: 991.9px)
  .invite-page-info,
  .invite-page-about,
  .invite-page-geo,
  .geo-work
    padding-top: 30px
  .col-mod,
  .col-mod-2
    width: 100%
  .container-form-invite
    padding: 0
  .form-invite-area
    padding: 15px
