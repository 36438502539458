.warranty-page
 padding-top: 44px
 //padding-bottom: 70px
 &__title
  color: #1A2939
  text-transform: uppercase
  font: 700 34px/130% 'Open Sans', sans-serif
  margin-bottom: 20px
 &__text
  color: #040404
  font: 400 18px/28px 'Open Sans', sans-serif
  @media all and (max-width: 575.9px)
   font: 400 14px/24px 'Open Sans', sans-serif
  strong
   font-weight: 700

.rules-row
 margin-top: 30px

.rules
 margin-bottom: 20px
 width: 100%
 height: 150px
 display: flex
 align-items: flex-end
 justify-content: center
 background-position: center
 background-size: contain
 background-repeat: no-repeat
 //&--first
 // background-image: url("../images/rules-1.png")
 //&--second
 // background-image: url("../images/rules-2.png")
 //&--third
 // background-image: url("../images/rules-3.png")
 //&--fourth
 // background-image: url("../images/rules-4.png")
 &__text
  position: relative
  bottom: 0
  color: #1E2225
  text-align: center
  text-transform: uppercase
  font: 700 12px/130% 'Open Sans', sans-serif

.warranty-desc-block
 border-radius: 10px
 overflow: hidden
 padding: 25px 95px 23px 40px
 width: 100%
 height: 100%
 min-height: 175px
 background-position: center
 background-repeat: no-repeat
 background-size: cover
 @media all and (max-width: 991.8px)
  padding-left: 15px
  padding-right: 15px
  display: flex
  flex-direction: column
  justify-content: center
 &--bg1
  //background-image: url("../../assets/images/warranty-1.png")
 &--bg2
  //background-image: url("../../assets/images/warranty-2.png")
 &__title
  color: #fff
  font: 800 18px/130% 'Open Sans', sans-serif
  margin-bottom: 10px
 &__text
  color: #fff
  font: 700 15px/150% 'Open Sans', sans-serif
  @media all and (max-width: 575.8px)
   font: 500 14px/24px 'Open Sans', sans-serif

.warranty-desc-block-row
 margin-top: 45px

.warranty-desc-block-col
 margin-bottom: 30px

.warranty-page-rules
 margin-top: 88px
 margin-bottom: 50px
 .title-line::after
  width: 58px
  top: -12px
 &__title
  text-transform: uppercase
  position: relative
  font: 700 24px/130% 'Open Sans', sans-serif
  margin-bottom: 30px

.warranty-page-service
 &__title

.service-row
 display: flex
 gap: 10px
 flex-wrap: wrap

.service
 min-width: 325px
 min-height: 150px
 background-color: #858796
 border-radius: 3px
 display: flex
 align-items: center
 flex-direction: column
 padding: 20px 15px 15px 15px
 margin-bottom: 10px
 @media all and (max-width: 1199.8px)
  min-width: auto !important
  flex: 1 1 100%
 @media all and (max-width: 991.8px)
  flex: 1 1 100%
 &:nth-child(2n)
  flex: 1 1 auto
 &__image
  margin-bottom: 10px
 &__text
  text-align: center
  color: #fff
  font: 700 15px/150% 'Open Sans', sans-serif
