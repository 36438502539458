$pt: 25px
$pb: 25px
.about-company-page
  position: relative
  //.breadcrumbs-area
  //  border-bottom: none
  //  background-color: rgba(242, 242, 242, 0.2)
  //  .breadcrumbs
  //    &__item::after
  //      background-color: #fff
  //    &__link
  //      color: #fff !important
  .breadcrumbs-area__container
    padding-top: 7px
    padding-bottom: 7px
  .about-company-page-info
    background-color: transparent
  .main-about
    background-color: #F4F4F4
    padding: 56px 0 50px 0


.about-company-page-banner
  position: relative
  min-height: 377px
  &::after
    content: ''
    width: 100%
    height: 25px
    position: absolute
    left: 0
    bottom: 0
    z-index: 1
    background-image: url("../../assets/images/about-compony-line.png")
  .main-banner__image
    z-index: -1
  &__container
    padding-top: 21px
  &__title-block
    padding: 47px 0 50px 0
  &__title
    color: #292E34
    text-transform: uppercase
    font: 800 40px/54px 'Open Sans', sans-serif
    margin-bottom: 29px
  &__sub-title
    max-width: 840px
    width: 100%
    color: #040404
    font: 400 21px/150% 'Open Sans', sans-serif
    strong
      font-weight: 700
  .title-bg
    width: 1120px
    height: 297px
    //background-image: url("../images/about-company-title-bg.png")
    &::after
      opacity: 0.9

.about-company-page-info
  background-color: #F2F2F2
  &__container
    padding-top: 26px
    padding-bottom: 20px
    @media all and (max-width: 991.8px)
      padding-top: 20px

.company-product-list
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  @media all and (max-width: 991.8px)
    justify-content: center

.company-product-item
  z-index: 1
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  //flex: 0 1 20%
  margin-bottom: 10px
  padding-top: 10px
  @media all and (max-width: 991.8px)
    flex: 0 1 50%
  &__image
    top: 0
    left: 50%
    position: absolute
    transform: translate(-50%, -100%)
    @media all and (max-width: 991.8px)
      display: none
  &__icon-area
    display: flex
    justify-content: center
    height: 45px
    align-items: flex-end
  &__icon
    margin: 0 auto
  &__text
    margin-top: 13px
    text-align: center
    text-transform: uppercase
    color: #1E2225
    font: 700 14px/19px 'Open Sans', sans-serif
    @media all and (max-width: 991.98px)
      font: 700 11px/15px "Open Sans", sans-serif
  &__image-tyre
    position: absolute
    top: -209px
    z-index: 1
    left: 50%
    transform: translateX(-50%)
    width: 210px
    height: 195px
    max-width: initial
    object-fit: cover
    @media all and (max-width: 991.98px)
      display: none

.production
  &__container
    padding-top: 97px
    padding-bottom: 20px
    @media (max-width: 991.8px)
      padding-top: $pt
  &__title-block
    margin: 0 0 24px 0
    h2
      width: 100%
      text-align: center
      //text-transform: uppercase
      color: #1A2939
      font: 700 34px/130% 'Open Sans', sans-serif
      @media all and (max-width: 575.9px)
        font: 700 26px/130% 'Open Sans', sans-serif
    &::after
      left: 50% !important
      transform: translateX(-50%)
      top: -16px !important
  &__text
    font: 400 18px/150% 'Open Sans', sans-serif
    color: #040404
    margin-bottom: 10px
    @media all and (max-width: 575.9px)
      font: 400 14px/150% 'Open Sans', sans-serif

.production-banners
  background-color: #6D6F7C
  //background-image: url("../images/product-banner-bg.png")
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  &__container
    position: relative
    padding-top: 45px
    padding-bottom: 30px
  &__image
    max-width: 100%
    display: block
    margin-right: auto
    margin-left: auto
    margin-bottom: 20px

.certificates
  background: #F2F2F2
  &__container
    padding-top: 70px
    padding-bottom: 35px
    @media (max-width: 991.8px)
      padding-top: $pt

.advantages
  &__container
    padding-top: 100px
    padding-bottom: 25px
    @media (max-width: 991.8px)
      padding-top: $pt

.advantages-list
  max-width: 1440px
  width: 100%
  display: flex
  flex-direction: column
  padding: 0
  .advantages-item:nth-child(2n)
    .advantages-item__image
      order: 2
    .advantages-item__info
      order: 1

.advantages-item
  display: flex
  min-height: 305px
  & > *
    flex: 0 0 50%
    display: flex
    position: relative
  @media (max-width: 991.8px)
    flex-direction: column
    & > *
      flex-direction: column
    &__image
      flex: 0 0 200px
      order: 1 !important
    &__info
      order: 2 !important
  &__image
    order: 1
    img
      position: absolute
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center
  &__info
    order: 2
    padding-top: 50px
    padding-left: 80px
    padding-right: 70px
    padding-bottom: 50px
    background-color: #6D6F7C
    flex-direction: column
    align-items: baseline
    @media (max-width: 991.8px)
      padding: 30px 12px
  &__title
    background-color: #fff
    padding: 10px 30px
    margin-bottom: 15px
    margin-left: 7px
    @media (max-width: 991.8px)
      padding: 10px
    span
      color: #1A2939
      font: 700 21px/150% 'Open Sans', sans-serif
      @media (max-width: 991.8px)
        font-size: 18px
  &__text
    color: #fff
    font: 700 16px/150% 'Open Sans', sans-serif
    @media all and (max-width: 991.9px)
      font: 500 14px/130% 'Open Sans', sans-serif
    @media all and (max-width: 575.9px)
      font: 500 14px/150% 'Open Sans', sans-serif

.companies-num
  background: #fff
  .production__title-block
    margin-bottom: 0
    h2
      color: #00408D
  &__container
    padding-top: 40px
    padding-bottom: 30px
    @media (max-width: 991.8px)
      padding-top: $pt
      padding-bottom: $pb


.connection
  background: #fff
  &__container
    padding-top: 10px
    padding-bottom: 10px

.connection-form
  display: flex
  align-items: center
  justify-content: space-between
  @media (max-width: 991.8px)
    flex-direction: column
  &__text
    color: #040404
    font: 400 18px/130% 'Open Sans', sans-serif
    margin-right: 17px
    @media (max-width: 575.8px)
      font: 500 15px/18px 'Open Sans', sans-serif
      padding-bottom: 10px
  &__input-container
    display: flex
    //align-items: baseline
    margin: 10px 0
    flex: 0 1 390px
    flex-direction: column
    //height: 37px
    @media (max-width: 991.8px)
      flex: 1 1 auto
      width: 100%
  &__form-control
    //border: none
    background-color: #E2E5ED
    border: 1px solid #CDCFD8
    border-top-right-radius: 0
    border-bottom-right-radius: 0
    padding-left: 25px
    &::placeholder
      color: #444550
      font: 400 18px/20px 'Open Sans', sans-serif
      @media all and (max-width: 991.98px)
        font: 400 15px/20px 'Open Sans', sans-serif
    @media all and (max-width: 991.98px)
      padding-left: 10px
  &__button-blue
    border-radius: 0 5px 5px 0
    width: 100%
    max-width: 142px
    height: inherit
    font: 700 15px/20px 'Open Sans', sans-serif
    @media (max-width: 991.8px)
      max-width: 120px

.companies-page-about-grid
  background-color: #F4F4F4
  .companies-num__container
    padding: 40px 0 80px 0
  .about-grid
    margin: 0px 15px 0 15px
    .about-grid__block
      height: 292px
      background-color: #fff
@media all and (max-width: 991.98px)
  .about-company-page-banner__container
    padding-top: 60px
  .about-company-page-banner__title-block
    padding: 0
  .about-company-page-banner__title
    font: 800 26px/35px 'Open Sans', sans-serif
    margin-bottom: 8px
  .about-company-page-banner__sub-title
    font: 400 13px/20px 'Open Sans', sans-serif
  .about-company-page-banner
    min-height: 256px
  .companies-page-about-grid .companies-num__container
    padding: 20px 0 40px 0
