$indentX: 15px
$indentY: 10px
.table-product-area
  overflow: hidden
  border-radius: 5px
  border: 1px solid #D2D2D2
  @media all and (max-width: 991.9px)
    border: none
    overflow: inherit

.table-product
  border-radius: 3px
  @media all and (max-width: 991.8px)
    //border: 1px solid #D2D2D2
  &__thead
    display: contents
    border-radius: 5px 5px 0 0
    @media all and (max-width: 991.8px)
      display: none
  &__title
    vertical-align: middle
    text-align: center
    background: #00408D
    padding: $indentY $indentX
    font: 700 15px/20px 'Open Sans', sans-serif
    text-transform: uppercase
    color: #fff
    border-right: 1px solid #fff
    th:last-child
      border: none
      @media all and (max-width: 991.8px)
        border-right: 1px solid #D2D2D2
        border-left: 1px solid #D2D2D2
    @media all and (max-width: 1199.8px)
      font: 700 14px/18px 'Open Sans', sans-serif
      padding: 10px
  &__body
    @media all and (max-width: 991.8px)
      display: flex
      flex-wrap: wrap
    tr:nth-child(1n)
      background-color: #F4F4F4
    tr:nth-child(2n)
      background-color: #ffffff
    tr:nth-child(3n)
      background-color: #E2E5ED
    td
      vertical-align: middle
      text-align: center
      border-right: 1px solid #D2D2D2
      @media all and (max-width: 991.8px)
        //border-left: 1px solid #D2D2D2
        border-bottom: 1px solid #D2D2D2
        display: flex
        justify-content: center
        & > *
          flex: 0 0 50%
      span, strong
        font: 400 13px/14px 'Open Sans', sans-serif
        padding: 7px 15px
        display: flex
        justify-content: center
        align-items: center
      a
        text-decoration-line: underline
      strong
        font-weight: 700
    td:last-child
      border: none
      @media all and (max-width: 991.8px)
        border-right: 1px solid #D2D2D2
        border-left: 1px solid #D2D2D2
  &__tr
    border-bottom: 1px solid #D2D2D2
    @media all and (max-width: 991.8px)
      display: flex
      flex-direction: column
      flex: 0 1 50%
      margin-bottom: 10px
      border-top: 1px solid #D2D2D2
    @media all and (max-width: 767.8px)
      flex: 0 1 100%
    .table-product__title
      display: none
      border-right: 1px solid transparent
      border-left: 1px solid transparent
      @media all and (max-width: 991.8px)
        display: block
      @media all and (max-width: 424.8px)
        font: 700 12px/15px "Open Sans", sans-serif
        padding: 10px 5px
  &__tr:last-child
    border: none
    @media all and (max-width: 991.8px)
      border-bottom: 1px solid #D2D2D2
      border-top: 1px solid #D2D2D2

.button-table
  min-height: 22px
  border-radius: 50px
  background-color: #5F616D
  //max-width: 100%
  width: 130px
  margin: 0 auto
  text-decoration-line: none !important
  padding: 3px
  position: relative
  span
    text-decoration-line: none
    color: #fff
    padding: 0 !important
    text-transform: uppercase
    font: 700 11px/14px 'Open Sans', sans-serif !important
  &:focus, &:focus-visible
    background-color: #E86200
  &:active, &:hover
    background-color: #E86200

.button-table-icon
  position: absolute
  right: 15px
  top: 5px

.table-product-block
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.15)
  background-color: #fff
  padding: 30px 20px 30px 20px
  margin-bottom: 60px
  @media all and (max-width: 991.9px)
    padding-top: 20px

