@font-face {
    font-family: 'Open Sans';
    src: url('OpenSansLight-Italic.woff2') format('woff2'),
        url('OpenSansLight-Italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans.woff2') format('woff2'),
        url('OpenSans.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-BoldItalic.woff2') format('woff2'),
        url('OpenSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Bold.woff2') format('woff2'),
        url('OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Light.woff2') format('woff2'),
        url('OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSansLight-Italic.woff2') format('woff2'),
        url('OpenSansLight-Italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-SemiboldItalic.woff2') format('woff2'),
        url('OpenSans-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans.woff2') format('woff2'),
        url('OpenSans.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Semibold.woff2') format('woff2'),
        url('OpenSans-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Italic.woff2') format('woff2'),
        url('OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Italic.woff2') format('woff2'),
        url('OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Extrabold.woff2') format('woff2'),
        url('OpenSans-Extrabold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Bold.woff2') format('woff2'),
        url('OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/*@font-face {*/
/*    font-family: 'Open Sans';*/
/*    src: url('OpenSans-Extrabold.woff2') format('woff2'),*/
/*        url('OpenSans-Extrabold.woff') format('woff');*/
/*    font-weight: bold;*/
/*    font-style: normal;*/
/*    font-display: swap;*/
/*}*/

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Light.woff2') format('woff2'),
        url('OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-BoldItalic.woff2') format('woff2'),
        url('OpenSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

/*@font-face {*/
/*    font-family: 'Open Sans';*/
/*    src: url('OpenSans-ExtraboldItalic.woff2') format('woff2'),*/
/*        url('OpenSans-ExtraboldItalic.woff') format('woff');*/
/*    font-weight: bold;*/
/*    font-style: italic;*/
/*    font-display: swap;*/
/*}*/

/*@font-face {*/
/*    font-family: 'Open Sans';*/
/*    src: url('OpenSans-ExtraboldItalic.woff2') format('woff2'),*/
/*        url('OpenSans-ExtraboldItalic.woff') format('woff');*/
/*    font-weight: bold;*/
/*    font-style: italic;*/
/*    font-display: swap;*/
/*}*/

