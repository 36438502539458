.category-title-block
  margin-bottom: 30px
  background-color: #EEF0F5
  border-radius: 3px
  padding: 6px 20px
  &__title
    color: #01509F
    font: 700 18px/130% 'Open Sans', sans-serif

.catalog-bg
  padding-bottom: 10px
  background-color: #fff
  position: relative
  &:before
    content: ''
    background-image: url("../../assets/images/catalog-bg.png")
    width: 100%
    height: 540px
    position: absolute
    bottom: 0
    z-index: 0

.catalog-filter-area
  user-select: none
  //background-color: #EEF0F5
  border-radius: 3px
  padding: 10px 0 65px 0
  &__button
    color: #1E2225
    text-transform: uppercase
    border: 1px solid #6D6F7C
    font: 700 15px/20px 'Open Sans', sans-serif
    min-height: 31px
    width: 100%
    &:active, &:hover, &:focus, &:focus-visible
      background-color: #E30613
      border-color: #E30613
      span
        color: #fff

.filter
  position: relative
  &--hide
    display: none
  &__header
    position: relative
  &__content
    //background-color: #EEF0F5
    padding: 18px 0px 20px 0px
  &__line
    width: 100%
    height: 1px
    background-color: #8C8C8C
    opacity: 1
    margin: 0

.filter-type
  //border: 1px solid #8C8C8C
  border-radius: 5px
  background-color: #fff
  overflow: hidden
  margin-bottom: 11px
  &__content
    position: relative
    display: flex
    overflow: hidden
    padding-right: 1px
  &__button:first-child
    border-right: none
    border-radius: 5px 0 0 5px
  &__button:last-child
    border-radius: 0 5px 5px 0
  &__button
    flex: 1 1 auto
    display: flex
    position: relative
    background-color: #fff
    padding: 0px 10px
    border: 1px solid #8C8C8C
    transition: 0.2s ease all
    &--active
      background-color: #00408D
      .filter-type__button-text
        color: #fff
        font-weight: 700
        &::before
          display: block
  &__button-text
    padding: 10px 0
    position: relative
    //text-transform: uppercase
    color: #0F1213
    font: 400 15px/100% 'Open Sans', sans-serif
//&::before
// display: none
// position: absolute
// height: 3px
// content: ''
// left: 0
// bottom: 0
// width: 100%
// background-color: #E30613

.filter-header
  position: relative
  background-color: #5F616D
  padding: 4px 5px 4px 10px
  border-radius: 5px
  //&::before
  //  content: ''
  //  position: absolute
  //  background-image: url("../icons/filter-border.svg")
  //  background-repeat: no-repeat
  //  width: 100%
  //  height: 100%
  //  left: 0
  //  top: 0
  //  bottom: 0
  &__title-block
    position: relative
  &__title
    margin-right: 15px
    display: inline-block
    color: #FFFFFF
    font: 700 17px/100% 'Open Sans', sans-serif
    span
      font-weight: 400
  &__button-reset
    position: relative
    color: #FFFFFF
    padding: 0
    border: none
    display: inline-block
    font: 400 15px/20px 'Open Sans', sans-serif
    &:before
      content: ''
      width: 100%
      position: absolute
      border-top: 1px dashed #fff
      bottom: -1px
      height: 1px
      left: 0

.filter-reset
  padding: 2px 5px
  margin: 0 auto
  span
    text-decoration-line: underline
    color: #E55A08
    font: 600 17px/22px 'Open Sans', sans-serif

.filter-apply
  padding: 2px 5px
  margin: 0 auto
  span
    text-decoration-line: underline
    font: 400 15px/17px 'Open Sans', sans-serif

.filter-button
  margin-bottom: 15px
  //max-width: 100px
  width: 100%
  background: #E2E5ED
  border-radius: 0px
  min-height: 43px
  span
    color: #1E2225
    //text-transform: uppercase
    font: 600 21px/29px "Open Sans", sans-serif
  &:focus, &:active, &:hover
    span
      color: #fff
    .count
      background-color: #fff
      color: #E86200
    svg path
      fill: #fff
  svg
    path
      transition: 0.3s ease-in-out all
    margin: 0 5px 0 10px
  .count
    font: 800 13px/15px "Open Sans", sans-serif
    color: #fff
    width: 21px
    height: 21px
    border-radius: 50%
    background-color: #E86200
    padding: 0
    display: flex
    align-items: center
    justify-content: center

.filter-content
  &__input-container
    margin-bottom: 20px

.form-check-filter
  margin-bottom: 15px
  position: relative
  font: 300 15px/19.5px 'Open Sans', sans-serif
  color: #1A1A1D
  input:checked ~ .checkbox
    background-color: #076cb5
  .form-check-input
    margin-right: 8px
    top: 1px
    position: relative
    background-color: #CDCFD5
    &[type="checkbox"]
      border-color: #CDCFD5
      border-radius: 0
      background-image: none
    &:checked
      background-color: #076CB5

.range-price
  margin-bottom: 20px
  .rc-slider-rail
    background-color: #7A7C87
  .rc-slider-track
    background-color: #E55A08
  .rc-slider-handle
    outline-width: 20px
    outline-color: #E55A08
    //border-color: #E55A08 !important
    opacity: 1
    background-color: #E55A08
    border: none !important
    @media all and (max-width: 575.9px)
      width: 16px
      height: 16px
      margin-top: -7px
    &:active
      //border-color: #E55A08
      box-shadow: none
      border: none !important
    &:hover
      //border-color: #E55A08
      border: none !important
    &:focus
      border: none !important
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging
    border-color: #E55A08
    box-shadow: none

.filter-range-price-inputs
  display: flex
  margin-bottom: 20px
  justify-content: space-between
  width: 100%
  input
    height: 35px
    flex: 0 0 48%
    width: inherit
    border: 1px solid #8C8C8C
    text-align: center
    background-color: #fff
    font: 700 15px/130% 'Open Sans', sans-serif
    border-radius: 5px

.area
  top: 5px
  margin-bottom: 10px
  @media all and (max-width: 991.9px)
    top: auto
    margin-bottom: 0

.catalog-view-type-sort,
.catalog-limit
//margin-bottom: 15px
//@media all and (max-width: 991.9px)
// margin-bottom: 15px
.catalog-view-type-sort
  &__sort-text
    color: #1E2225
    font: 600 15px/26px 'Open Sans', sans-serif

.catalog-view-type-sort
  display: flex
  align-items: center

.catalog-view-type-sort-select
  position: relative
  margin-left: 5px
  &__current
    color: #E55A08
    font: 400 15px/17px 'Open Sans', sans-serif
    position: relative
    padding: 0px
    border-radius: 0
    &--open
      img
        transform: rotate(180deg)
    img
      position: absolute
      top: 5px
      right: -17px
    &:before
      content: ''
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      border-bottom: 1px dashed #7E808B
  &__drop
    position: absolute
    bottom: -3px
    transform: translate(0, 100%)
    z-index: 10
  &__list
    background-color: #fff
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15)
    border-radius: 5px
    padding: 10px
  &__item
    position: relative
    &:last-child
      .catalog-view-type-sort-select__button
        margin-bottom: 0
        padding-bottom: 0
        border-bottom: none
  &__button
    white-space: nowrap
    border: none
    width: 100%
    justify-content: flex-start
    border-radius: 0
    padding: 0
    margin-bottom: 6px
    padding-bottom: 6px
    border-bottom: 1px dashed #7E808B
    font: 600 13px/13px 'Open Sans', sans-serif
    &--active
      color: #00408D
    &:hover
      color: #00408D
//.catalog-view-type-sort__item:focus
//.catalog-view-type-sort__item--active,
//.catalog-view-type-sort__item:hover,
//.catalog-view-type-sort__item:active
//  color: #01509F
//  font-weight: 700
//  &::after
//    border-color: #076CB5

.catalog-limit
  align-items: center
  margin-right: 0
  display: flex
  &__item, span
    position: relative
    color: #1E2225
    font-size: 13px
    margin-right: 2px
  span
    margin-right: 0px
  &__item
    &:last-child
      margin-right: 0
      &::before
        display: none
    &::before
      content: ''
      position: absolute
      right: -2px
      //top: 7px
      width: 1px
      height: 13px
      background-color: #60656E
    &::after
      display: none
    &--active
      font-weight: 700
      color: #E55A08
    &:hover, &:active, &:focus
      font-weight: 700
      color: #E55A08

.bubble-area
  margin-bottom: 15px
  position: relative
  border: 1px solid #8C8C8C
  border-left: none
  border-right: none
  padding-bottom: 0px
  @media all and (max-width: 991.9px)
    padding-bottom: 5px

.bubble-list
  display: flex
  flex-wrap: wrap
  align-items: center
  padding-top: 5px
  max-width: 100%
  @media all and (max-width: 991.9px)
    overflow-x: auto
    flex-wrap: nowrap

.bubble
  cursor: pointer
  width: max-content
  background: #D2D6E1
  padding: 5px 5px 5px 25px
  border-radius: 30px
  display: flex
  align-items: center
  margin: 0 5px 5px 0
  @media all and (max-width: 991.9px)
    margin-bottom: 5px
  &:last-child
    margin-right: 0
  &:hover
    .bubble__button
      opacity: 1
  span
    white-space: nowrap
    color: #0F1213
    text-transform: uppercase
    font: 600 13px/14px 'Open Sans', sans-serif
  &__button
    flex: 0 0 15px
    width: 15px
    //opacity: 0
    padding: 0
    margin-left: 8px
    @media all and (max-width: 991.9px)
      opacity: 1

.catalog-block
  border-radius: 5px
  padding: 23px 30px 20px 30px
  margin-bottom: 30px
  @media (max-width: 567px)
    padding-left: 12px
    padding-right: 12px
  .cart-block__title-block
    margin-bottom: 10px
  .area
    margin-bottom: 26px

.class-hz
  display: flex
  justify-content: flex-end
  align-items: center
  flex-grow: 1
  position: relative
  top: 6px

@media all and (max-width: 575.9px)
  .catalog-block .area
    flex-direction: column
    margin-bottom: 15px
  .catalog-view-type-sort
    margin-bottom: 10px
  .class-hz
    width: 100%
    top: 0
    justify-content: flex-start
  .model-page-title-block
    margin-bottom: 15px
    .cart-block__title,
    .count-product
      margin-bottom: 10px
    
