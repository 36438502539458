.cart-banner
  min-height: 195px
  position: relative
  padding-top: 65px
  @media all and (max-width: 575.8px)
    padding-top: 30px
    min-height: 150px
  &__image
    top: 0
    left: 0
    position: absolute
    width: 100%
    height: 100%
    object-fit: cover
    object-position: bottom
    @media all and (max-width: 967.98px)
      object-position: left
  &__container
    position: relative
    z-index: 1
  &__title-block
    display: flex
    align-items: center
  // padding-top: 63px
  // padding-bottom: 66px
  &__title
    color: #39404A
    font: 800 40px/54px 'Open Sans', sans-serif
    margin: 0
    @media all and (max-width: 575.8px)
      font: 800 35px/52px "Open Sans", sans-serif
  .title-bg
    height: 100%
    //background-image: url("../images/cart-banner-bg.png")
    &::after
      opacity: 0.9

.cart-page
  background-color: #d2d3d7
//.breadcrumbs-area
//  background: #6D6F7C
//  border: none
//  &__container
//    padding-top: 6px
//    padding-bottom: 6px
//.breadcrumbs__link
//  color: #fff !important
//.breadcrumbs__item::after
//  background-color: #fff

.cart-area-new
  &__container
    z-index: 1
    position: relative
    @media (min-width: 0px)
      padding-left: 0
      padding-right: 0
      max-width: 100%
    @media (min-width: 576px)
      max-width: 573px
    @media (min-width: 768px)
      max-width: 753px
    @media (min-width: 992px)
      max-width: 993px
    @media (min-width: 1200px)
      max-width: 1173px
//@media (min-width: 1400px)
//  max-width: 1353px

.cart-area-bg
  position: relative
  background-color: #F4F4F4
  &--pb
    padding-bottom: 30px
//background-image: url("../images/cart-area-bg.jpg")
//background-repeat: repeat-y
//transition: 0.2s ease all
//background-position: center
//background-size: cover
//background-position: 100% -30%
//background-attachment: fixed

.cart-block
  padding: 20px 30px 20px 30px
  background-color: #F4F4F4
  border-radius: 10px
  &--new
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.10)
    border-radius: 0 0 10px 10px
    padding: 30px 20px 20px 20px
    background-color: #fff
  @media (max-width: 567px)
    padding-left: 12px
    padding-right: 12px
  &__title-block
    //min-height: 50px
    //padding: 8px 13px
    display: flex
    align-items: center
    //background: #EEF0F5
    //border-radius: 0 5px 5px 0
    //clip-path: polygon(0 0, 100% 0%, calc(100% - 10px) 100%, 0% 100%)
    margin-bottom: 23px
    @media all and (max-width: 575.8px)
  //margin-left: -12px
  &__title
    margin-right: 12px
    margin-bottom: 0
    color: #1E2225
    font: 700 34px/130% 'Open Sans', sans-serif
    position: relative
    text-transform: initial
    @media all and (max-width: 575.8px)
      font: 700 16px/130% 'Open Sans', sans-serif
  &__button-blue
    //text-transform: uppercase
    font: 700 17px/20px 'Open Sans', sans-serif
    //max-width: 265px
    //width: 100%
    min-height: 40px
    padding: 5px 48px
    color: #F6F6F8

.model-block
  background-color: #fff
  margin-top: 30px
  margin-bottom: 30px
  padding: 20px
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.15)

.count-product
  top: 6px
  position: relative
  color: #1A2B3E
  font: 400 17px/26px 'Open Sans', sans-serif
  span
    font-weight: bold
  &__count
    position: relative
    //top: 3px
    font: 700 17px/17px 'Open Sans', sans-serif
    @media all and (max-width: 575.9px)
      top: 0
  @media all and (max-width: 575.9px)
    font: 400 16px/26px 'Open Sans', sans-serif
    top: 0
  &:after
    content: ''
    width: 1px
    height: 80%
    left: -5px
    background-color: #1A2B3E
    position: absolute
    bottom: 5px
    @media all and (max-width: 575.9px)
      height: 60%
      bottom: 5px

.select-product
  display: flex
  align-items: center
  //border-bottom: 1px solid  #E5E5E5
  padding: 0 10px 7px 15px
  //margin-bottom: 20px
  .form-check
    margin-right: 29px
  &__buttons
    display: flex
    align-items: baseline
    flex-wrap: wrap
  &__button
    padding: 0
    min-height: auto
    font: 400 15px/26px 'Open Sans', sans-serif
    position: relative
  .all
    color: #6D6F7C
    //color: #E30613
    margin-right: 20px
    &::after
      background-color: #E30613
      content: ''
      position: absolute
      right: -10px
      top: 6px
      height: 14px
      width: 1px
      transform: rotate(15deg)
      display: none
  .remove
    color: #6D6F7C

.cart-list

.method-list

.method-button
  position: relative
  border: 1px solid #E5E5E5
  border-radius: 10px
  width: 100%
  height: 145px
  flex-direction: column
  padding-top: 35px
  justify-content: flex-start
  margin-bottom: 10px
  &__circle
    position: absolute
    top: 10px
    left: 15px
    border: 1px solid #6D6F7C
    width: 21px
    height: 21px
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    padding: 3px
    span
      display: none
      border-radius: 50%
      width: 100%
      height: 100%
      background-color: #01509F
  &__text
    font: 600 15px/130% 'Open Sans', sans-serif
    text-transform: uppercase
    color: #000000
    padding: 6px
  &__content
    display: flex
    flex-direction: column
    align-items: center

.method-button--active
  .method-button__circle
    border-color: #01509F
    span
      display: block
  svg
    path
      fill: #01509F
    rect
      stroke: #01509F

.form-order
  &__row-area
    border-bottom: 1px solid #E5E5E5
    margin-bottom: 12px
  &__title
    margin-bottom: 12px
    color: #272727
    font: 700 21px/23px 'Open Sans', sans-serif
  .mb-3
    margin-bottom: 20px
  .form-check
    padding-left: 0
    margin: 0
    cursor: pointer
    color: #6D6F7C
    font: 400 15px/130% 'Open Sans', sans-serif
    a
      display: inline
      align-items: center
      color: #01509F
      text-decoration-line: underline
      margin-right: 5px
  &__form-control
    background: #E2E5ED
    font: 400 15px/26px 'Open Sans', sans-serif
    .form-control::placeholder
      color: #6D6F7C
      font: 400 15px/26px 'Open Sans', sans-serif
  &__form
    margin-bottom: 20px
    .form-check-input
      position: relative
      top: 2px
      margin-right: 5px

.fields-mandatory
  margin: 0 0 5px 0
  color: #6D6F7C
  font: 400 13px/18px 'Open Sans', sans-serif
  font-style: italic

.form-delivery
  width: 100%
  display: flex
  flex-wrap: wrap
  margin-bottom: 20px
  margin-top: 26px
  .form-check
    align-items: center
    margin-right: 20px
    .form-check-input
      margin-right: 10px
      width: 20px
      height: 20px
      border-radius: 0
    .checkbox
      border-color: #CDCFD8
      background-color: #E2E5ED
      &:after
        width: 11px
        height: 9px
        left: 3px
        top: 4px
  .fields-mandatory
    margin: 16px 0 0 0
  textarea
    height: 55px
  &__comment
    margin-top: 0
    margin-bottom: 0

.form-delivery-area
  padding-bottom: 20px
  //border-bottom: 1px solid #E5E5E5
  &__info
    color: #272727
    font: 400 15px/21px 'Open Sans', sans-serif

.delivery-organization-area
  display: flex
  flex-wrap: wrap
  justify-content: center
  padding: 20px 100px
  margin: 0 -20px
  //border-bottom: 1px solid #E5E5E5
  background-color: #E2E5ED
  &__block
    display: flex
    align-items: center
    border-radius: 3px
    background-color: #fff
    width: 100%
    justify-content: space-between
    padding: 4px 20px
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15)

.delivery-organization-logo
  margin: 5px

.cart-block-total-price
  padding-bottom: 10px
  display: flex
  padding-top: 30px
  justify-content: space-between
  align-items: center
  @media (max-width: 767px)
    flex-direction: column
    & > *
      margin-bottom: 10px

.cart-total-price-area
  display: flex
  justify-content: space-between

.total-price
  &__text
    text-transform: uppercase
    color: #6D6F7C
    font: 600 18px/26px 'Open Sans', sans-serif
  &__price
    color: #E86200
    text-transform: uppercase
    font: 600 26px/26px 'Open Sans', sans-serif
  &__rub
    text-transform: uppercase
    color: #6D6F7C
    font: 400 26px/26px 'Open Sans', sans-serif

.cart-wrapper-bloсks
  .cart-block
    padding-bottom: 30px
  .payment-method
    padding-top: 0
    padding-bottom: 20px
  .cart-order
    padding-top: 0
    padding-bottom: 25px
  .making-order
    padding-bottom: 15px

.cart-stub
  padding: 0px 30px 0px 30px
  @media all and (max-width: 575.9px)
    padding: 15px 0
  &__content
    position: relative
  &__text
    font: 400 21px/28px 'Open Sans', sans-serif
    color: #040404
    strong
      font-weight: 700
  .red
    font-weight: 600
    color: #E86200
  .blue
    color: #01509F
  &__text-info
    display: inline-block
    margin-top: 10px
    color: #212121
    font: 400 15px/130% 'Open Sans', sans-serif
    border-bottom: 1px solid #D6DFE6
    padding-bottom: 15px
  &__buttons
    padding-top: 30px
  //padding-bottom: 30px
  &__button
    background: #01509F
    max-width: 210px
    width: 100%
    min-height: 42px
    font: 700 15px/20px 'Open Sans', sans-serif
  &__image-block
    border-top: 1px solid #D6DFE6
    padding-top: 50px

@media (max-width: 991.8px)
  .select-product
    padding-left: 0
    padding-right: 0
    .form-check
      margin-right: 10px
  .select-product__button
    font-size: 14px

.cart-block__title-block--new
  margin-bottom: 13px
  .cart-block__title
    color: #00408D
    font: 700 28px/130% 'Open Sans', sans-serif
  .count-product
    top: 4px
    &:after
      bottom: 3px
      background-color: #6D6F7C
    span
      font-weight: 400
  .count-product__count
    top: auto

.cart-product-list-block
  margin-top: 0
  background-color: #fff
  border-radius: 10px
  margin-bottom: 30px
  padding: 20px
  box-shadow: 0px 2px 14px rgb(0 0 0 / 15%)
  .cart-block__title
    color: #00408D
    font: 700 28px/110% 'Open Sans', sans-serif

.form-order__row-area--last
  border-bottom: none
  margin-bottom: 0

@media (max-width: 991.8px)
  .delivery-organization-area
    padding-left: 20px
    padding-right: 20px
  .delivery-organization-area__block
    justify-content: center
    flex-wrap: wrap
