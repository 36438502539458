$px: 30px
.thanks-modal
 background: #FFFFFF
 border: 1px solid #DBDBDB
 padding-top: 24px
 @media all and (max-width: 767.9px)
  width: 100%
 &__header
  justify-content: space-between
  display: flex
  align-items: center
  margin-bottom: 25px
  padding-left: $px
  padding-right: $px
  @media all and (max-width: 575.9px)
   padding-left: $px / 2
   padding-right: $px /2
 &__title
  color: #01509F
  font: 600 21px/130% 'Open Sans', sans-serif
 &__content
  padding-left: $px
  padding-right: $px
  padding-bottom: $px/2
  @media all and (max-width: 575.9px)
   padding-left: $px / 2
   padding-right: $px /2
 &__footer
  padding-left: $px
  padding-right: $px
  background: #F1F1F1
  padding-top: 12px
  padding-bottom: 12px
  display: flex
  justify-content: space-between
  @media all and (max-width: 767.9px)
   flex-direction: column
  @media all and (max-width: 575.9px)
   padding-left: $px / 2
   padding-right: $px /2
 &__buttons
  margin: -5px
  display: flex
  align-items: center
  justify-content: flex-end
  flex: 1 1 auto
  @media all and (max-width: 767.9px)
   justify-content: center
  @media all and (max-width: 575.9px)
   flex-wrap: wrap
  .button
   margin: 5px
