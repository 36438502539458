.cart-product-window
  position: absolute
  z-index: 10
  right: 0px
  bottom: -22px
  transform: translateY(100%)
  .cart-stub
    padding: 0
    &__buttons
      padding: 15px 0
      .button
        //max-width: 100%
  .cart-stub__image-block
    padding: 10px
    img
      width: 20%
  &__content
    width: 375px
    border-radius: 5px
    background-color: #FFFFFF
    position: relative
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2)
    padding: 15px 10px 5px 20px
  &__list
    padding-right: 10px
    max-height: 255px
    overflow-y: auto
    overflow-x: hidden

.cart-product-window-item
  display: flex
  padding: 10px 0
  position: relative
  border-bottom: 1px solid #D6DFE6
  &__delete
    cursor: pointer
    padding: 5px
    position: absolute
    right: -5px
    top: 5px
    z-index: 10
  &__image
    flex: 0 0 100px
    width: 100px
    height: 100px
    border: 1px solid #D6DFE6
    border-radius: 5px
    overflow: hidden
    img
      border-radius: 5px
  &__content
    position: relative
    margin-left: 20px
    padding-right: 10px
    span
      padding-left: 5px
    .price
      color: #005A9B
      text-transform: uppercase
      font: 700 18px/25px 'Open Sans', sans-serif
      span
        font-weight: 400
        color: #7E808B
    .old-price
      font: 600 18px/25px 'Open Sans', sans-serif
      color: #6D6F7C
      span
        font-weight: 400
        color: #7E808B
  &__name
    padding-right: 5px
    display: inline-block
    text-decoration-line: underline
    color: #212121
    font: 600 18px/130% 'Open Sans', sans-serif
    margin-bottom: 20px
  &__footer
    display: flex
    align-items: center
    justify-content: space-between
  &__button-blue
    margin-left: 10px
    color: #F6F6F8
    text-transform: uppercase
    padding: 6px 20px
    font: 700 13px/18px 'Open Sans', sans-serif
  &__price
    padding: 10px 0
    align-items: center
    p
      text-transform: uppercase
      margin-right: 5px
      color: #364352
      font: 400 18px/25px 'Open Sans', sans-serif


