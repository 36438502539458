.delivery-page-banner
  height: 195px
  position: relative
  .title-bg
    width: 100%
    height: 100%
  &__container
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
  .main-banner__title-block
    padding: 0
    margin: 0
    z-index: 1
    position: relative
  &__title
    font: 800 40px/54px 'Open Sans', sans-serif
    margin: 0
    @media (max-width: 575px)
      font: 800 25px/30px "Open Sans", sans-serif

.delivery-page
  .breadcrumbs-area
    //background: #6D6F7C
    border: none
    &__container
      padding-top: 7px
      padding-bottom: 7px
  .breadcrumbs__link
    //color: #fff !important
  .breadcrumbs__item::after
    //background-color: #fff

.delivery-page-payment
  padding: 27px 0 35px 0
  background-color: #F6F6F6
  &__title-block
    margin-bottom: 31px
    h2
      color: #00408D
      width: 100%
      text-align: center
    &::after
      left: 50% !important
      transform: translateX(-50%)
      top: -15px !important

.delivery-text
  margin-bottom: 15px
  font: 400 15px/22px 'Open Sans', sans-serif
.delivery-payment
  &__list-container
    // background-color: #6D6F7C
    position: relative
    z-index: 1
  &__item
    border-radius: 3px 3px 0 0
    position: relative
    width: 100%
    overflow: hidden
    padding-top: 128px
    min-height: 302px
    display: flex
    margin-bottom: 25px
  &__item-container
    background: rgba(70, 72, 83, 0.8)
    display: flex
    flex-direction: column
    //gap: 25px
    align-items: flex-start
    padding-top: 38px
    padding-bottom: 25px
    padding-left: 0
    padding-right: 0
    min-height: 175px
    position: relative
    z-index: 1
    @media all and (max-width: 991.8px)
      padding-right: 12px
      padding-left: 12px
  &__bg
    width: 100%
    height: 100%
    // margin-bottom: 32px
    object-fit: cover
    object-position: center
    position: absolute
    top: 0
  &__layout
    width: 100%
    height: calc(100% - 125px)
    object-fit: cover
    object-position: right
    position: absolute
    bottom: 0
  &__title
    color: #fff
    font-weight: 800
    font-size: 21px
    line-height: 25px
    // margin-bottom: 20px
    text-transform: uppercase
    z-index: 2
    padding-left: 40px
    padding-right: 40px
    margin-bottom: 20px
  &__text
    color: #fff
    font-weight: 700
    font-size: 15px
    // margin-bottom: 48px
    z-index: 2
    line-height: 140%
    padding-left: 40px
    padding-right: 100px
    &--new
      padding-right: 40px
    @media all and (max-width: 991.8px)
      padding-left: 0
    @media all and (max-width: 575.9px)
      font-size: 14px
      font-weight: 500

.payment
  &__list-container
    background-color: #F6F6F6
    padding: 25px 0 0
  &__h3
    color: #00408D
    font-weight: 700
    font-size: 36px
    width: 100%
    line-height: 130%
    text-align: center
    margin-bottom: 4px
  &__p
    color: #1E2225
    font-weight: 600
    font-size: 18px
    width: 100%
    text-align: center
    margin-bottom: 35px
  &__list
    max-width: 680px
    margin: 0 auto
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    position: relative
    @media all and (max-width: 567.9px)
      flex-direction: column
      align-items: center
  &__list-item
    //padding: 0 15px
    display: flex
    //width: 100%
    max-width: 185px
    width: 100%
    flex-direction: column
    justify-content: center
    align-items: center
  &__list-text
    text-align: center
    margin-top: 15px
    font-weight: 600
    font-size: 17px
    line-height: 130%
    font-family: "Open Sans", sans-serif
    color: #000000

.delivery-page-delivery
  padding: 30px 0 31px 0
  background-color: #fff
  .delivery-page-payment__title-block
    margin-bottom: 21px
  &__title-p
    font-weight: 400
    font-size: 18px
    line-height: 150%
    width: 100%
    text-align: center
    color: #040404
    margin-bottom: 35px
    @media all and (max-width: 575.9px)
      font-size: 14px

.delivery-rewind__title-p
  margin-top: 35px
  margin-bottom: 0
  text-align: left
  font: 400 15px/150% 'Open Sans', sans-serif
  span
    font-weight: 700
    color: #E30613
  strong
    font-weight: 700

.delivery-payment
  &__p
    margin-top: 35px
    font-weight: 400
    font-size: 15px
    line-height: 150%
    color: #040404
    @media all and (max-width: 575.9px)
      font-size: 14px
  &__p + &__p
    margin-top: 20px

.delivery-rewind
  background-color: #fff
  &__big-p
    font-weight: 700
    font-size: 21px
    line-height: 150%
    color: #01509F
    margin-bottom: 25px
    @media all and (max-width: 575.9px)
      font-size: 15px
      font-weight: 600
  &__p
    font-weight: 400
    font-size: 15px
    line-height: 150%
    color: #040404
    margin-bottom: 25px
    @media all and (max-width: 575.9px)
      font-size: 14px
  &__h3
    font-weight: 700
    font-size: 24px
    line-height: 130%
    text-transform: uppercase
    color: #1A2939
    width: 100%
    text-align: center
    margin-bottom: 25px
  &__white
    background-color: #fff
    margin-top: -55px

.rules-row__delivery-rewind
  padding-top: 5px

.delivery-get-area
  background-color: #F6F6F6
  padding: 34px 0 20px 0
  &__text
    color: #040404
    font: 400 15px/22px 'Open Sans', sans-serif
    margin-bottom: 50px
  .payment__p
    margin-bottom: 50px

.get-list
  display: flex
  max-width: 650px
  width: 100%
  margin: 0 auto
  justify-content: space-between
  @media all and (max-width: 567.9px)
    flex-direction: column
    align-items: center

.get-item
  text-transform: uppercase
  color: #000000
  font: 600 15px/19px 'Open Sans', sans-serif
  text-align: center
  position: relative
  margin-bottom: 30px
  &__icon
    margin-left: -20px
    position: relative
  small
    color: #565863
    display: block
    font: 400 10px/13px 'Open Sans', sans-serif

.get-rule
  color: #040404
  font: 400 15px/150% 'Open Sans', sans-serif
  position: relative
  padding-left: 20px
  margin-bottom: 20px
  &:after
    position: absolute
    left: 0
    top: 5px
    content: ''
    width: 9px
    height: 9px
    border-radius: 50%
    background: #E30613
  strong
    font-weight: 700

.delivery-map
  background: #F6F6F6
  padding-top: 35px
  &__title-block
    justify-content: center
    margin-bottom: 25px

@media all and (max-width: 1199.8px)
  .delivery-payment__item
    gap: 15px
  .delivery-payment__title
    font-size: 18px
  .delivery-payment__text
    font-size: 14px
    padding-right: 100px
  .delivery-payment__item-container
    gap: 15px

@media all and (max-width: 991.8px)
  .payment__list-item
    margin-bottom: 30px
  .delivery-rewind__big-p
    font-size: 18px
  .delivery-payment__list
    flex-direction: column
    gap: 0
  .delivery-payment__item
    position: relative
  .delivery-payment__bg,
  .delivery-payment__item,
  .delivery-payment__layout
    width: 100%
  .delivery-payment__layout
    object-position: center
  .delivery-payment__layout--left
    right: 0
    left: 0
  .delivery-payment__bg
    left: 0
    right: 0
  .delivery-payment__layout--right
    left: 0
    right: 0
  .delivery-payment__title
    padding-left: 0
  .delivery-payment__text--right
    padding-left: 0

@media all and (max-width: 575.9px)
  .delivery-payment__text
    font-weight: 600
    padding-right: 0
  .delivery-payment__layout
    object-position: left
