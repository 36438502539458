.technologies-area
 position: relative
 border-bottom: 20px solid #6D6F7C
 &:last-child
  border-bottom: none
 &__title-block
  background-color: #fff
  border-bottom: 4px solid #E30613
  padding: 33px 10px
 &__title
  margin-bottom: 0
  color: #1A2939
  text-transform: uppercase
  font: 700 34px/130% 'Open Sans', sans-serif
  text-align: center
  @media all and (max-width: 575.9px)
   font-size: 20px

.technologies-nav-area
 display: flex
 background: #EEF0F5
 margin-bottom: 17px
 justify-content: center

.technologies-nav
 display: flex
 justify-content: center
 width: 100%
 @media all and (max-width: 991.8px)
  flex-direction: column
 &__item
  display: flex
  align-items: center
  justify-content: center
  padding: 10px 30px
  transform: skewX(-15deg)
  position: relative
  flex: 0 0 auto
  &:after
   content: ''
   position: absolute
   height: 100%
   width: 1px
   background-color: #6D6F7C
   right: 0
  @media all and (max-width: 991.8px)
   transform: skewX(0deg)
   justify-content: center !important
  &:before
   top: 0
   display: none
   content: ''
   position: absolute
   width: 100%
   height: 100%
   background-color: #6D6F7C
   left: 0
   right: 0
 &__item:first-child
  text-align: right
  flex: 1 1 auto
  justify-content: flex-end
  &:before
   left: 20px
   transform: translateX(-100%)
 &__item:last-child
  text-align: left
  flex: 1 1 auto
  justify-content: flex-start
  &:before
   left: -20px
   transform: translateX(100%)
  &:after
   display: none
 &__link
  color: #1A2939
  font: 600 17px/23px 'Open Sans', sans-serif
  transform: skewX(15deg)
  display: block
  cursor: pointer
  text-align: center
  @media all and (max-width: 991.8px)
   transform: skewX(0deg)

.technologies-nav__item--active
 background-color: #6D6F7C
 &:before
  display: block
 .technologies-nav__link
  color: #fff
  font-weight: 700

.technologies-info-slide
 position: relative
 background-color: #F6F6F6
 overflow: hidden

.technologies-info
 position: absolute
 opacity: 0
 //visibility: hidden
 top: 0
 left: 0
 right: 0
 z-index: 0
 transform: scale(1.05)
 background-color: #F6F6F6
 &--active
  //visibility: visible
  transition: 0.5s ease all
  z-index: 1
  opacity: 1
  position: relative
  transform: scale(1)
 &__image-block
  position: relative
  overflow: hidden
  max-height: 320px
 &__image
  //position: absolute
  object-fit: contain
  object-position: center
  width: 100%
  height: 100%
 &__info
  padding: 33px 0 28px 0
  @media all and (max-width: 575.8px)
   padding: 15px 0 28px
 &__title
  color: #292E34
  font: 700 21px/130% 'Open Sans', sans-serif
  text-transform: uppercase
  margin-bottom: 30px
  @media all and (max-width: 575.8px)
   font: 700 18px/130% 'Open Sans', sans-serif
   margin-bottom: 15px
 &__desc
  color: #040404
  font: 400 15px/150% 'Open Sans', sans-serif
  @media all and (max-width: 575.8px)
   font: 400 13px/150% 'Open Sans', sans-serif

@media all and (max-width: 991.8px)
 .technologies-nav__item::after
  right: auto
  left: 50%
  transform: translateX(-50%)
  bottom: 0
  height: 1px
  width: 20%
