$nav: 22px
.header
  z-index: 101
  position: relative
  background: #fff
  &__line-up
    display: flex
    align-items: center
    justify-content: center
    background: #5F616D
    padding: 7px 20px 0 20px
    min-height: 38px
  &__text
    //text-align: center
    white-space: nowrap
    color: #fff
    font: 500 16px/22px 'Open Sans', sans-serif
    @media all and (max-width: 767.9px)
      white-space: inherit
  &__contacts
    display: flex
    justify-content: flex-end
  &__line-content
    display: flex
    align-items: center
    justify-content: space-between
    max-width: 1400px
    width: 100%
    & > *
      flex: 1 1 33.33%
      padding-bottom: 7px

.header-contacts
  display: flex
  align-items: center
  & > *:not(:last-child)
    border-right: 1px solid #B8B8B8
    margin-right: 5px
    padding-right: 5px
  &__order-call
    position: relative
    display: inline-block
    a, .order-call-btn
      background-color: transparent
      border: none
      white-space: nowrap
      color: #fff
      font: bold 15px/15px 'Open Sans', sans-serif
      text-decoration-line: underline
      &:active, &:hover, &:focus, &:focus-visible
        color: #fff
        text-decoration-line: underline
  &__mail
    text-decoration-line: none
    color: #F6F6F8
    font: bold 15px/15px 'Open Sans', sans-serif
    white-space: nowrap
    transition: 0.2s ease color
    &:focus, &:hover, &:active
      color: #F6F6F8
  &__phone
    font-weight: 600
    color: #FFC8A0
    &:focus, &:hover, &:active
      color: #FFC8A0

.header-contacts__order-call
  .modal-order-call
    right: -50%
    transform: translateY(100%)
    bottom: -7px

.modal-order-call
  z-index: 100
  position: absolute
  //top: calc(100% + 6px)
  //left: -50px
  background-color: #fff
  width: 254px
  padding: 22px
  border-radius: 3px
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2)
  &__header
    white-space: nowrap
    display: flex
    align-items: center
    justify-content: space-between
  &__title
    font-size: 21px
  &__close
    position: absolute
    right: 14px
    top: 20px
    cursor: pointer
    border: none
    background-color: #fff
  &__p
    margin-top: 10px
    font-size: 13px
    line-height: 17px
    color: #101216
  &__agreement
    display: flex
    justify-content: space-between
    align-items: end
    .checkbox
      margin-top: 3px
      margin-left: 10px
    label
      font-size: 13px
      color: #101216
      cursor: pointer
      a
        font-size: 13px
        color: #01509F
        font-weight: 400
        &:hover
          color: #01509F
        &::selection
          background-color: transparent
      &::selection
        background-color: transparent
    & input
      cursor: pointer
  &__form
    margin-top: 15px
  &__form-group
    margin-bottom: 20px
  &__form-error
    display: flex
    color: #E86200
    font-size: 13px
    align-items: center
    justify-content: flex-start
    margin-top: 10px
    margin-left: 5px
    img
      margin-right: 7px
  &__form-control
    border: 1px solid #D6DFE6
    border-radius: 5px
    font-size: 14px
    //line-height: 1
    padding: 2px 8px
    line-height: 23px
    &::placeholder
      color: #8C8C8C
    &:focus
      box-shadow: none
      border: 1px solid #D6DFE6
  &__form-control.modal-order-call__form-control--error
    color: #E86200
    border: 1px solid #E86200
  &__form-bottom
    display: flex
    align-items: center
    justify-content: center
  &__button-red
    font-size: 13px
    color: #fff
    font-weight: 700
    text-transform: uppercase
    background-color: #E86200
    border-radius: 50px
    padding: 6px 22px

.header-main
  background-color: #00408D
  padding: 0px 20px
  display: flex
  align-items: center
  justify-content: center
  min-height: 80px
  @media all and (max-width: 1199.8px)
    justify-content: flex-start
  @media all and (max-width: 991.8px)
    padding-left: 12px
    padding-right: 12px
    justify-content: space-between
  &__logo
    position: relative
    //top: -6px
    max-width: 250px
    width: 100%
    img
      position: relative
      max-width: 100%
      object-fit: contain
    @media all and (max-width: 1200px)
      top: 0
    @media all and (max-width: 991.8px)
      margin-right: 10px
  &__navigation
    justify-content: center
    width: 100%
    flex-wrap: nowrap
    position: relative
  &__item
    position: relative
  &__link
    letter-spacing: 0.2px
    white-space: nowrap
    padding: 30px 0
    margin: 0 22px
    color: #fff
    text-transform: uppercase
    font: bold 15px/20px 'Open Sans', sans-serif
    position: relative
    @media all and (max-width: 1399.8px)
      margin: 0 15px
      font: bold 14px/20px 'Open Sans', sans-serif
    @media all and (max-width: 1199.8px)
      //margin: 0 10px
      font: bold 13px/20px 'Open Sans', sans-serif
    span
      position: relative
    &::after
      content: ''
      //display: none
      opacity: 0
      position: absolute
      left: 0
      bottom: 0px
      width: 100%
      height: 5px
      transition: 0.3s ease opacity
      background-color: #E86200
    &:active, &:hover, &:focus, &:focus-visible
      color: #fff
    &--active::after
      opacity: 1
  &__icon-arrow
    transition: 0.3s ease all
    position: absolute
    top: 5px
    right: -18px
  &__content
    max-width: 1400px
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between

.button-search-area
  //right: -10px
  display: flex
  position: relative
  @media all and (max-width: 1199.9px)
    right: 7px
    top: 2px
.search-line-area
  position: absolute
  height: 40px
  right: 0
  width: 0
  overflow: hidden
  transition: 0.3s ease all
  //top: -5px
  z-index: 10
  //top: 2px
  &--open
    overflow: visible
    .search-line__content
      padding: 0 40px 0 20px
    @media all and (min-width: 1200px)
      width: 40vw
    @media all and (max-width: 1199.8px)
      //top: 2px
      width: 62vw

.header-footer-mobile-search
  .search-line-area
    overflow: visible

.search-line
  position: relative
  height: 100%
  &__content
    transition: 0.3s ease all
    padding: 0
    background-color: #FFFFFF
    border-radius: 3px
    border: 1px solid #D8D8D8
    //clip-path: polygon(1% 0, 100% 0%, 100% 100%, 0% 100%)
    position: relative
    height: 100%
    .form-control
      height: inherit
      border: none
      border-radius: 0
      outline: none
      color: #6D6F7C
      background-color: #fff
      padding: 0
      font: 400 16px/22px 'Open Sans', sans-serif
      &:hover
        background-color: #fff
  .button-search
    position: absolute
    top: 7px
    right: 0

.search-drop-area
  position: relative
  top: 5px
  width: 100%
  z-index: 1

.search-result
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15)
  border-radius: 3px
  background-color: #fff
  overflow-x: hidden
  overflow-y: auto
  max-height: 300px
  position: relative
  &__link
    display: inline-block
    width: 100%
    background-color: #fff
    padding: 10px 15px
    color: #040404
    font: 400 15px/130% 'Open Sans', sans-serif
    transition: 0.2s ease all
    border-bottom: 1px solid #DBDBDB
    &:hover, &.focus, &:focus-visible
      outline: none
      box-shadow: none
      background-color: rgba(71, 75, 79, 0.2)
      font-weight: 600
    &:last-child
      border-bottom: none

.button-search
  //padding: 10px
  position: relative
  img
    max-width: inherit

.button-cart-area
  position: relative

.button-cart
  justify-content: flex-start
  margin-left: 15px
  //flex: 0 1 240px
  top: -2px
  position: relative
  min-height: 42px
  background-color: #FFFFFF
  border-radius: 3px
  color: #fff
  padding: 10px 22px 10px 22px
  text-decoration-line: none
  white-space: nowrap
  @media all and (max-width: 1199.8px)
    margin-left: auto
    flex: 0 0 auto
    top: 0
    padding: 10px
  &:hover, &:active, &:focus, &:focus-visible
    color: #fff
  &__icon
    max-width: inherit
    margin-right: 10px
    @media all and (max-width: 575.9px)
      width: 17px
      height: auto
  &__text
    text-transform: uppercase
    color: #2E3541
    font: 700 15px/20px 'Open Sans', sans-serif
    padding-right: 10px
    margin-right: 11px
    border-right: 1.5px solid #7E808B
    @media all and (max-width: 1199.8px)
      font: 700 14px/19px 'Open Sans', sans-serif
    @media all and (max-width: 767.9px)
      display: none
  &__total-price
    color: #2E3541
    font: bold 15px/23px 'Open Sans', sans-serif
  &__rub
    color: #2E3541
    font: 400 15px/23px 'Open Sans', sans-serif

.button-cart__price
  .spinner-border
    width: 1rem
    height: 1rem
    border-width: 0.15rem

.header-main__item:hover
  .header-main-sublinks-area
    opacity: 1
    pointer-events: all
    width: max-content
    .header-main-sublinks
      margin-top: 5px
  .header-main__link
    color: #fff
    &::after
      opacity: 1

.header-main-sublinks-area
  pointer-events: none
  position: absolute
  left: $nav
  z-index: 10
  bottom: -30px
  transform: translateY(100%)
  opacity: 0
  transition: 0.3s ease all
  @media all and (max-width: 1399.8px)
    left: 15px

.header-main-sublinks
  margin-top: 0px
  display: flex
  flex-direction: column
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2)
  transition: 0.3s ease all
  &__item
    display: flex
    background-color: #FFFFFF
    padding: 13px 15px 13px 15px
    border-bottom: 1px solid #BCBCBC
    transition: 0.3s ease all
    cursor: pointer
    &:hover
      background-color: #252735
      .header-main-sublinks__text
        color: #fff
  &__item:last-child
    border-bottom: none
  &__item--active
    background-color: #252735
    .header-main-sublinks__text
      color: #fff
  &__icon
    margin-right: 10px
  &__text
    text-transform: uppercase
    white-space: nowrap
    color: #1E2225
    font: 600 12px/130% 'Open Sans', sans-serif

.mobile-menu-button
  position: relative
  //top: 30px
  //right: 0
  margin: 0 0 0 20px
  width: 50px
  flex: 0 0 50px
  height: 50px
  background: #fff
  border-radius: 3px
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  input
    display: block
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0
    cursor: pointer
    opacity: 0
    /* hide this */
    z-index: 2
    /* and place it over the hamburger */
    -webkit-touch-callout: none
  span
    transform-origin: 13px 1.5px
    display: block
    width: 22px
    height: 3px
    margin-bottom: 5px
    position: relative
    background: #076CB5
    z-index: 1
    //transform-origin: 4px 0px
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), opacity 0.55s ease
  span
    &:first-child
    //transform-origin: 0% 0%
    &:nth-last-child(2)
    //transform-origin: 0% 100%
    &:last-child
      margin-bottom: 0
  input
    &:checked
      & ~ span
        margin: 0
        //margin-left: 12.5px
        width: 22px
        opacity: 1
        transform: rotate(45deg) translate(0)
        background: #076CB5
        &:nth-last-child(3)
          opacity: 0
          transform: rotate(0deg) scale(0.2, 0.2)
        &:nth-last-child(2)
          transform: rotate(-45deg) translate(0)

.header__line-content
  //@media all and (max-width: 1199.8px)

  @media all and (max-width: 991.8px)
    padding: 0px 12px 0 12px
    flex-direction: column
    align-items: center

.header-main-mobile
  position: relative
  z-index: 2
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15)
  background-color: #00408D
  &__content
    justify-content: space-between
    display: flex
    align-items: center
    padding: 20px 15px
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15)
    @media all and (max-width: 575.9px)
      flex-direction: column
      align-items: center
      //padding: 20px 15px
    .header-contacts__phone
      color: #FFFFFF
      margin-right: 30px
  &__logo-area
    max-width: 152px
    width: 100%
    margin-right: 20px
    @media all and (max-width: 575.9px)
      margin-right: 0
      margin-bottom: 14px

.button-mobile-cart
  .button-cart__price, .button-cart__rub
    color: #fff
    white-space: nowrap
    font: 700 15px/20px 'Open Sans', sans-serif

.button-mobile-call
  padding: 0
  width: 17px
  flex: 0 0 17px
  margin-right: 5px

.button-mobile-call--area
  //flex: 0 0 17px
  position: relative
  z-index: 2
  padding: 3px 0 3px 10px
  .modal-order-call
    left: 50%
    bottom: -10px
    transform: translate(-50%, 100%)
    @media all and (max-width: 991.9px)
      left: auto
      right: -70px
      transform: translate(0%, 100%)

.header-footer-mobile
  position: relative
  padding: 10px 15px
  background-color: #EEF0F5
  border-radius: 0 0 5px 5px
  &__content
    display: flex
    align-items: center
    background-color: #F1F1F1
    border-radius: 0 0 3px

.button-mobile-cart
  padding-right: 0
  @media all and (max-width: 991.98px)
    padding-top: 0
    padding-bottom: 0

.button-mobile-menu
  padding: 10px 0
  flex-direction: column
  span
    margin: 3px 0
    width: 25px
    height: 2px
    background-color: #252735

.header-footer-mobile-search
  width: 100%
  margin-left: 10px
  .search-line-area
    position: relative
    z-index: 1
    display: block !important
    height: 43px
    top: auto
    width: 100%
  .search-line__content
    padding-left: 15px
  .search-line .button-search
    right: 5px

@media all and (max-width: 991.8px)
  .header__line-up
    padding: 10px 40px
    text-align: center
    background: #5F616D
  .header__text
    font-size: 12px
    line-height: 140%
    text-decoration-line: underline
    padding-bottom: 0
    color: #FFFFFF
  .header-contacts
    display: none

