.fade-enter
  opacity: 1
  transform: translateX(5%)
  &.fade-enter-active
    opacity: 1
    transform: translateX(0)
    transition: opacity 300ms, transform 300ms
.fade-exit
  opacity: 0.5
  transform: translateX(0)
  &.fade-exit-active
    opacity: 0.5
    transform: translateX(-5%)
    transition: opacity 300ms, transform 300ms
