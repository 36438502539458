$grid-gutter-width: 1.5rem
$gutters: 0
html
  min-width: 320px
  height: 100%
// -webkit-text-size-adjust: none
//*
// -webkit-overflow-scrolling: touch
// -ms-touch-action: manipulation
// touch-action: manipulation !important

body
  //touch-action: manipulation
  min-height: 100%
  min-width: 320px

html, body
  overflow-x: hidden

body
  font-family: "Open Sans", sans-serif
  color: #1A2B3E
  font-size: 16px

img
  max-width: 100%

.form-control
  //background-color: #E2E5ED
  padding-left: 10px
  padding-right: 10px
  border: 1px solid #CDCFD8
  border-radius: 5px
  &:focus, &:hover, &:active
    outline: none
    box-shadow: none
    border-color: #00408D
    background-color: #E2E5ED

strong
  font-weight: 700
  display: inline

html.with-fancybox
  overflow: hidden

::-webkit-scrollbar
  width: 5px
  height: 3px
/* ширина scrollbar */

::-webkit-scrollbar-track
  background: #fff
/* цвет дорожки */

::-webkit-scrollbar-thumb
  background-color: #C4C5C9
  /* цвет плашки */
  border-radius: 10px
  /* закругления плашки */
  border: 5px solid #C4C5C9
/* padding вокруг плашки */


.form-check
  cursor: pointer
  display: flex
  margin: 0
  padding: 0
  user-select: none
  min-height: auto
  .form-check-input
    margin: 0
    width: 15px
    height: 15px
    border: 1px solid #8C8C8C
    float: inherit
    display: none
    &[type='radio'],
    &[type='checkbox']
      border-radius: 3px
      background-image: url("../icons/check.svg")
  .checkbox
    display: inline-block
    border: 1px solid #8C8C8C
    width: 20px
    height: 20px
    flex: 0 0 20px
    margin-right: 10px
    border-radius: 3px
    position: relative
    &::after
      content: ''
      position: absolute
      display: none
      background-image: url("../../assets/icons/check.svg")
      width: 9px
      height: 7px
      background-size: cover
      left: 2px
      top: 3px
  input:checked ~ .checkbox
    background-color: #00408D
    &::after
      display: block
  .form-check-input:checked
    box-shadow: none
    border-color: #00408D
  &__agreem
    display: inline
    .form-check-input
      position: relative
      top: 2px

.agreement
  input
    display: none
  .checkbox
    margin-right: 5px
    width: 15px
    height: 15px
    flex: 0 0 15px

.form-check-input
  &:focus, &:hover, &:active
    box-shadow: none
    filter: none

.form-label
  color: #272727
  margin-bottom: 3px
  text-transform: uppercase
  font: 400 13px/26px 'Open Sans', sans-serif
  white-space: nowrap

.form-label-hidden
  @media (max-width: 767px)
    display: none

textarea
  color: #272727
  font: 400 15px/26px 'Open Sans', sans-serif
  resize: none

a
  //all: unset
  color: inherit
  text-decoration-line: none
  &:active, &:hover, &:focus, &:focus-visible
    color: inherit
    text-decoration-line: none

.container-fluid
  padding-left: 0
  padding-right: 0

.alert
  position: absolute

.alert-enter
  opacity: 0
  transform: scale(0.9)

.alert-enter-active
  opacity: 1
  transform: translateX(0)
  transition: opacity 300ms, transform 300ms

.alert-exit
  opacity: 1

.alert-exit-active
  opacity: 0
  transform: scale(0.9)
  transition: opacity 300ms, transform 300ms

h2
  font: bold 36px/110% 'Open Sans', sans-serif
  color: #292E34
  text-transform: inherit
  margin-bottom: 20px
  position: relative

.title-line
  &:after
    content: ''
    position: absolute
    border-radius: 3px
    background-color: #E30613
    width: 67px
    height: 5px
    top: -14px
    left: 0

.title-bg
  //width: 850px
  //height: 231px
  position: relative
  background-size: contain
  background-repeat: no-repeat
  //background-image: url("../images/title-bg.png")
  @media all and (max-width: 991.8px)
  //width: 100% !important
  //height: 100% !important
  &::after
    display: none
    content: ''
    opacity: 0.8
    position: absolute
    background-color: #FFFFFF
    left: -100%
    top: 0
    height: inherit
    width: 100%
//&::before
//  content: ''
//  position: absolute

.main-banner
  position: relative
  min-height: 882px
  padding: 555px 0 50px 0
  &__invite
    position: absolute
    right: 0
    top: 160px
    z-index: 10
    &__image
      width: 232px
    @media all and (max-width: 1024px)
      top: 80px
      width: 100px
    @media all and (max-width: 567.9px)
      top: 10px
  @media all and (max-width: 1139.9px)
    min-height: 882px
    padding: 555px 0 50px 0
  @media all and (max-width: 991.9px)
    min-height: 500px
    padding: 300px 0 50px 0
  @media all and (max-width: 767.9px)
    padding: 270px 0 60px 0
    min-height: 540px
  &__image
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    object-fit: cover
    object-position: bottom
  &__title-block
    padding: 160px 0 40px 0
    margin-bottom: 30px
    @media all and (max-width: 991.9px)
      padding-top: 30px
    @media all and (max-width: 575.9px)
      padding-top: 60px
  &__title
    word-break: break-word
    color: #39404A
    text-transform: uppercase
    margin-bottom: 34px
    font: 800 55px/130% 'Open Sans', sans-serif
    @media (max-width: 991.9px)
      font: 800 46px/70px 'Open Sans', sans-serif
    @media (max-width: 767.9px)
      font: 800 28px/30px "Open Sans", sans-serif
  &__sub-title
    max-width: 100%
    width: 100%
    color: #fff
    text-transform: uppercase
    font: 500 21px/150% 'Roboto', sans-serif
    @media (max-width: 767.9px)
      font: 400 20px/24px 'Open Sans', sans-serif
  &__buttons
    display: flex
    align-items: center
  &__button
    font: bold 16px/22px 'Open Sans', sans-serif
    max-width: 250px
    width: 100%
    padding: 9px 15px
    margin-right: 0px
    margin-left: 5px

.page-main-banner
  object-position: top

.navigation-area
  background-color: #EEF0F5
  border-top: 5px solid #E30613
  border-bottom: 5px solid #FFFFFF

.navigation
  display: flex
  @media all and (max-width: 991.8px)
    flex-direction: column
  &__item
    flex: 1 1 auto
    text-align: center
    position: relative
    &:before
      transform: rotate(15deg)
      right: 0
      height: 100%
      width: 1px
      content: ''
      background-color: #6D6F7C
      position: absolute
    @media all and (max-width: 991.8px)
      &:before
        display: none
  &__item:last-child
    &:before
      display: none
  &__link
    padding: 6px 5px 11px 5px
    display: block
    font: 400 17px/23px 'Open Sans', sans-serif

.bg-gray
  background-color: #F6F6F6

.navigation-model-area
  padding: 55px 0 80px 0

.navigation-model
  background-color: #FFFFFF
  min-height: 77px
  display: flex
  @media all and (max-width: 991.8px)
    flex-direction: column
  &__block
    display: flex
    align-items: center
    justify-content: center
    padding: 5px 10px
    flex: 1 1 33.3%
    position: relative
    &:after
      position: absolute
      content: ''
      right: 0
      top: 10px
      border-right: 1px dashed #6D6F7C
      height: 57px
    @media all and (max-width: 991.8px)
      padding: 15px
      &:after
        height: 1px
        width: 100%
        border-right: none
        border-bottom: 1px dashed #6D6F7C
        top: auto
        left: auto
        right: auto
        bottom: 0
  &__block:last-child::after
    display: none
  &__image
    margin-right: 30px
  &__name
    transition: 0.2s ease-in color
    text-transform: uppercase
    font: bold 14px/19px 'Open Sans', sans-serif
    &:active, &:hover, &:focus, &:focus-visible
      color: #E30613

.main-about
  padding: 61px 0 69px 0
  &__body
    padding-left: 8px
    @media all and (max-width: 767.9px)
      padding-left: 0
  &__title
    position: relative
    text-transform: inherit
    color: #00408D
    margin-bottom: 18px
    line-height: 96%
  .title-line:after
    top: -14px
  &__sub-title
    margin-bottom: 19px
    color: #E55A08
    font: 700 23px/130% 'Open Sans', sans-serif
    font-style: italic
  &__info
    color: #040404
    font: 400 15px/150% 'Open Sans', sans-serif
    margin-bottom: 54px
    strong
      text-transform: uppercase
      i
        font-style: italic
    a
      color: #00408D
      font-size: 15px
      font-weight: 700
      line-height: 150%
    @media all and (max-width: 575.9px)
      font-size: 14px
  &__pre
    margin-bottom: 25px
  &__button-area
    background: #F6F6F6
    height: 40px
    width: 97%
    position: relative
    left: 10px
  &__button-blue
    height: inherit
    max-width: 330px
    width: 100%
    text-transform: inherit
    font: bold 17px/23px 'Open Sans', sans-serif

.about-grid
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  grid-template-rows: 1fr 1fr 1fr
  gap: 0px 0px
  grid-template-areas: ". . ." ". . ." ". . ."
  border-radius: 10px
  overflow: hidden
  border: 1px solid #BDBDBD
  position: relative
  top: 7px
  left: 4px
  @media (max-width: 1199.8px)
    left: auto
    top: auto
    margin-top: 30px
  @media (max-width: 767px)
    grid-template-areas: ". ." ". ." ". ."
    grid-template-columns: 1fr 1fr
    grid-template-rows: 1fr 1fr
  &__block
    background-color: #fff
    flex-direction: column
    text-align: center
    height: 295px
    position: relative
    display: flex
    align-items: center
    justify-content: center
    padding: 20px
    @media (max-width: 767px)
      height: auto !important
      &:last-child
        display: none
  &__image
    position: absolute
    object-fit: cover
    width: 100%
    height: 100%
    object-position: center
  &__title
    color: #E86200
    text-transform: uppercase
    font: bold 44px/130% 'Open Sans', sans-serif
    margin-bottom: 19px
    @media all and (max-width: 767.98px)
      font: bold 36px/130% 'Open Sans', sans-serif
  &__info
    text-transform: uppercase
    color: #1A1A1D
    font: 700 21px/150% 'Open Sans', sans-serif
    padding-right: 15px
    @media (max-width: 1199.8px)
      padding-right: 0
    @media all and (max-width: 767.98px)
      font: bold 18px/130% 'Open Sans', sans-serif
  &__desc
    color: #040404
    font: 400 18px/150% 'Open Sans', sans-serif
    top: 24px
    position: relative
    @media all and (max-width: 767.98px)
      font: 400 16px/130% 'Open Sans', sans-serif
    @media all and (max-width: 575.9px)
      top: auto
      margin-top: 20px

.delivery-area
  padding: 39px 0 22px 0
  @media all and (max-width: 575.9px)
    padding: 30px 0 20px 0
  &__title
    color: #00408D
    margin-bottom: 15px !important
  .delivery-area__title
    margin-bottom: 35px !important
  &__button-blue
    text-transform: inherit
    max-width: 300px
    width: 100%
    font: bold 17px/23px 'Open Sans', sans-serif
    //margin-bottom: 20px
    padding: 10px
    //border-radius: 3px
    @media all and (max-width: 575.9px)
      max-width: 100%

.main-page-map
  .contact-map
    margin-bottom: 0

.delivery-info-list
  display: flex
  gap: 10px
  @media all and (max-width: 991.9px)
    flex-direction: column
    gap: 0

.delivery-info
  padding: 20px 100px
  display: flex
  flex-direction: column
  //justify-content: center
  position: relative
  min-height: 175px
  margin-bottom: 30px
  border-radius: 3px
  flex: 1
  overflow: hidden
  @media all and (max-width: 991.9px)
    padding: 20px 90px 20px 20px
    margin-bottom: 20px
  //@media all and (max-width: 575.9px)
   //padding: 20px
  &__bg
    position: absolute
    left: 0
    top: 0
    object-fit: cover
    width: 100%
    height: 100%
    z-index: 0
    object-position: left
    @media all and (max-width: 1199.9px)
      object-position: right
  &__title
    position: relative
    z-index: 1
    text-transform: uppercase
    color: #FFFFFF
    font: 800 18px/130% 'Open Sans', sans-serif
    margin-bottom: 10px
  &__text
    position: relative
    z-index: 1
    color: #FFFFFF
    font: 700 15px/140% 'Open Sans', sans-serif
    @media all and (max-width: 575.9px)
      font: 500 14px/140% 'Open Sans', sans-serif

.delivery
  justify-content: space-between
  display: flex
  padding: 0 65px
  @media all and (max-width: 1199.9px)
    flex-wrap: wrap
    padding: 0
  &__item
    //align-items: center
    display: flex
    flex-direction: column
    position: relative
    margin-bottom: 45px
    &--i1
      margin-top: 3px
      left: -2px
    &--i2
      margin-top: 96px
      left: -22px
    &--i3
      margin-top: 3px
      left: -43px
    &--i4
      margin-top: 96px
      left: -69px
    @media all and (max-width: 1199.9px)
      margin-top: 0
      left: auto
    @media all and (max-width: 575.9px)
      width: 50%
  &__arrow
    pointer-events: none
    position: absolute
    //height: 100%
    width: 196px
    right: -153px
    //top: -62px
    top: 27%
    @media all and (max-width: 991.9px)
      display: none
  &__title
    text-align: center
    color: #1E2225
    margin-bottom: 18px
    text-transform: uppercase
    font: 700 17px/130% 'Open Sans', sans-serif
    @media all and (max-width: 575.9px)
      font: 700 13px/130% "Open Sans", sans-serif
  &__image
    width: 115px
    height: 115px
    margin: 0 auto 24px auto
    @media all and (max-width: 575.9px)
      width: 60px
      height: 60px
      margin-bottom: 10px
  &__text
    max-width: 245px
    width: 100%
    //padding: 0 10px

    color: #000
    font: 700 15px/140% 'Open Sans', sans-serif
    @media all and (max-width: 575.9px)
      text-align: center
      font: 500 13px/120% "Open Sans", sans-serif
  &__list
    position: relative
    max-width: 230px
    margin-top: 12px
    width: 100%
    @media all and (max-width: 575.9px)
      display: none
    p:last-child
      margin-bottom: 0
    p
      padding-left: 15px
      text-transform: uppercase
      color: #040404
      font: 400 12px/130% 'Open Sans', sans-serif
      margin-bottom: 9px
      position: relative
      &:after
        content: ''
        width: 6px
        height: 6px
        border-radius: 50%
        background-color: #01509F
        position: absolute
        top: 5px
        left: 0

.delivery__item--i1
  .delivery__arrow
    right: -84px
    top: -28px
    width: 139px
    transform: rotate(45deg)

.delivery__item--i2
  .delivery__arrow
    width: 139px
    right: -80px
    top: -73px
    transform: rotate(0deg)

.delivery__item--i3
  .delivery__arrow
    right: -74px
    top: -25px
    width: 138px
    transform: rotate(45deg)

.contact-area
  position: relative
  &__image
    position: absolute
    width: 100%
    height: 100%
  .title-block
    margin-bottom: 0
  .delivery-area__title,
  .title-block__link
    color: #fff
  &__block
    padding-top: 30px
    padding-bottom: 40px
  &__info
    position: relative
    z-index: 1
    font: 600 17px/26px 'Open Sans', sans-serif
    color: #fff
    display: flex
    align-items: end
    flex-wrap: wrap
    @media all and (max-width: 575.9px)
      font: 600 17px/26px 'Open Sans', sans-serif
    img
      position: relative
      top: 8px
      margin: 0 10px 2px 10px
      width: 38px
      height: 35px

.title-block
  position: relative
  display: flex
  align-items: baseline
  margin: 0 0 15px 0
  h2
    margin: 0
  &__link
    color: #6D6F7C
    margin: 0 15px
    text-decoration-line: underline
    font: 600 18px/130% 'Open Sans', sans-serif
    font-style: italic

.contact-desc
  background: #6D6F7C
  padding-top: 39px
  padding-bottom: 46px
  &__info
    color: #fff
    font: 600 18px/150% 'Open Sans', sans-serif
    @media all and (max-width: 575.9px)
      font: 500 16px/130% 'Open Sans', sans-serif

.models-area
  padding-top: 38px
  padding-bottom: 37px
  background-image: url("../../assets/images/models-bg.jpg")
  width: 100%
  min-height: 590px
  background-position: center
  background-size: cover
  background-repeat: no-repeat
  .title-block
    margin-bottom: 40px
  h2, .title-block__link
    color: #fff

.count
  position: relative
  width: 95px
  border: 1px solid #E5E5E5
  border-radius: 5px
  display: flex
  background-color: #fff
  & > *
    flex: 1 1 33.33%
  .button
    touch-action: manipulation
    width: 30px
    padding: 0
    font: 600 20px/27px 'Open Sans', sans-serif
    &:hover
      background-color: #EEF0F5
    &:focus
      background-color: #EEF0F5
  .form-control
    color: #272727
    font: 600 18px/25px 'Open Sans', sans-serif
    text-align: center
    padding: 1px
    border-radius: 0
    border-left: 1px solid #E5E5E5
    border-right: 1px solid #E5E5E5
    border-top: none
    border-bottom: none
    &:read-only
      background-color: #fff
      width: 30px

.form-check-select-product
  .form-check-input
    width: 20px
    height: 20px
    border-color: #6D6F7C
    background-size: initial
    &:checked
      background-color: #076CB5
      border-color: #076CB5

.swiper-slide
  user-select: none

.modal-dialog
  margin: 0 auto
  max-width: max-content !important

.pagination-area
  //margin-top: 25px
  position: relative
  display: flex
  justify-content: flex-end
  width: 100%
  @media all and (max-width: 991.8px)
    justify-content: center

.pagination__button._prev
  @media all and (max-width: 991.8px)
    margin-top: 15px
    order: 3

.pagination__button._next
  @media all and (max-width: 991.8px)
    margin-bottom: 15px
    order: 1

.pagination
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15)
  user-select: none
  display: flex
  margin-bottom: 20px
  //width: 100%
  flex-wrap: wrap
  background-color: #fff
  border-radius: 5px
  justify-content: flex-end
  @media all and (max-width: 991.8px)
    justify-content: center
    box-shadow: none
    background-color: transparent
  @media all and (max-width: 767.8px)
    width: 100%
  &__item
    //margin: 0 4px 0px 4px
    background-color: #fff
    margin: 0 10px 0 0
    display: flex
    align-items: center
    @media all and (max-width: 991.8px)
      order: 2
      margin: 0
      border-radius: 0
    &:first-child
      margin-left: 0
    &:last-child
      margin-right: 0
    &.selected
      position: relative
      //background-color: #E2E5ED
      margin-right: 0
      & + .pagination__item
        margin-left: 10px
      .pagination__link
        background-color: #EEF0F5
        font-weight: 700
  &__dots
    margin: 0 8px
    font-size: 16px
    display: flex
    align-items: center
    pointer-events: none
    @media all and (max-width: 991.8px)
      order: 2
  &__link
    text-align: center
    display: inline-block
    width: 100%
    transition: 0.2s ease all
    border-radius: 3px
    color: #1E2225
    font: 600 17px/19px 'Open Sans', sans-serif
    padding: 6px 13px
    &:hover
      background-color: #EEF0F5
      font-weight: 700
  &__button
    text-align: center
    display: flex
    align-items: center
    justify-content: center
    transition: 0.2s ease all
    border-radius: 3px
    color: #1E2225
    font: 400 15px/19px 'Open Sans', sans-serif
    background-color: #EEF0F5
    font-weight: 700
    //margin: 0 4px 4px 4px

    a
      flex: 0 0 90px
      width: 90px
      padding: 6px 12px
    @media all and (max-width: 991.8px)
      width: 100%
      background-color: transparent
      a
        transition: 0.3s ease-in-out all
        background-color: #EEF0F5
        max-width: 250px
        width: 100%
        flex: 0 0 250px
        border-radius: 50px
        &:hover, &--active
          background-color: #E86200
          color: #fff
    //max-width: 250px
    &.disabled
      display: none
    &:hover, &--active
      background-color: #E86200
      color: #fff
      @media all and (max-width: 991.8px)
        background-color: transparent
        a
          color: #fff

.col-model
  margin-bottom: 30px

@media all and (max-width: 991.8px)
  h2
    font-size: 26px
  .title-block
    flex-direction: column
  .title-block__link
    margin: 5px 0

.pagination .pagination__button._prev ~ .pagination__item,
.pagination__dots
  //background-color: red
  margin: 0
  border-radius: 0
  position: relative
  &:after
    content: ''
    width: 100%
    height: 3px
    position: absolute
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15)
    left: 0
    bottom: 0
    z-index: -1
  .pagination__link
    border-radius: 0

.pagination .pagination__item:nth-last-child(-n+2)
  .pagination__link
    border-radius: 0

.pagination__dots
  background-color: #fff
  margin: 0
  padding: 0 5px

.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl
  padding-right: 15px
  padding-left: 15px

@media (min-width: 1400px)
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl
    max-width: 1140px

.row
  margin-right: -15px
  margin-left: -15px

.row > *
  padding-right: 15px
  padding-left: 15px

.container-politics
  padding-top: 20px
  padding-bottom: 20px
  h1
    word-break: break-word
    font: 700 44px/116% "Open Sans", sans-serif
    margin-bottom: 20px
  h2
    margin-bottom: 15px
    font: 600 21px/26px "Open Sans", sans-serif
    color: #00408D
  p
    font: 400 17px/25px "Open Sans", sans-serif
    color: #0c0c0c
    margin-bottom: 25px

.cookie-area
  position: fixed
  z-index: 999
  inset: auto 1.25rem 1.25rem auto
  background-color: #f0ede8
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 30%)
  max-width: 400px
  width: 100%
  border-radius: 5px
  @media all and (max-width: 575.9px)
    max-width: 100%
    inset: auto 0 0 0
    border-radius: 0

.cookie
  padding: 15px 25px
  position: relative
  &__text
    color: #000000
    font-family: 'Open Sans', sans-serif
    font-size: 15px
    line-height: 18px
    font-weight: 300
  &__button
    position: absolute
    top: 0.425rem
    right: 0.425rem

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none

.main-about,
.models-area,
.delivery-area,
.contact-area__block
  padding-top: 40px
  padding-bottom: 40px

.image-about
  height: 480px
  .slider-about__slide
    height: 100%
    img
      width: 100%
      height: 100%

.main-about__body--about-page
  .main-about__title
    margin-bottom: 25px
  .main-about__sub-title
    margin-bottom: 21px

@media all and (max-width: 767.9px)
  .image-about
    margin-bottom: 30px
