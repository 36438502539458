$pb: 45px
.slider-models
  position: relative
  .swiper
    padding-bottom: $pb
    border-radius: 10px
  .prev, .next
    //background: red
    top: calc(50% - #{$pb})
    position: absolute
    z-index: 10
    width: 26px
    height: 52px
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    cursor: pointer
  .prev
    background-image: url("../../assets/icons/arrow.svg")
    left: -50px
  .next
    background-image: url("../../assets/icons/arrow.svg")
    transform: scale(-1, 1)
    right: -50px
  .swiper-pagination-bullet
    width: 28px
    height: 6px
    background: #BDBDBD
    border-radius: 3px
  .swiper-pagination-bullet-active
    background: #FFFFFF
  .next.swiper-button-disabled
    pointer-events: none
    transform: scale(1, 1)
    background-image: url("../../assets/icons/arrow-disable.svg")
  .prev.swiper-button-disabled
    pointer-events: none
    transform: scale(-1, 1)
    background-image: url("../../assets/icons/arrow-disable.svg")

.slider-certificates
  margin-top: 40px
  .swiper-slide
    //transition: 0.2s ease all!important
    max-width: 446px
    width: 100%
    //height: 642px
    img
      max-width: 100%
  .swiper-wrapper
    align-items: center
  .swiper-pagination-bullet
    background: #BDBDBD
  .swiper-pagination-bullet-active
    background: #6D6F7C
  .prev
    transform: scale(-1, 1)
    background-image: url("../../assets/icons/arrow-disable.svg")
    @media (max-width: 767px)
      display: none
  .next
    transform: scale(1, 1)
    background-image: url("../../assets/icons/arrow-disable.svg")
    @media (max-width: 767px)
      display: none
  .prev.swiper-button-disabled,
  .next.swiper-button-disabled
    opacity: 0.5
  .swiper-3d .swiper-slide-shadow-left,
  .swiper-3d .swiper-slide-shadow-right
    background-image: linear-gradient(to left, rgba(0, 0, 0, .05), rgba(0, 0, 0, 0))

.slider-product-area
  margin-bottom: 30px
  .product-card
    user-select: none

.slider-main
  z-index: 0
  position: absolute
  width: 100%
  height: 100%
  left: 0
  top: 0
  .main-banner__title
    color: #fff
  .swiper
    padding-bottom: 0
    border-radius: 0
    height: inherit
  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom, .swiper-pagination-fraction
    margin-bottom: 15px
  .swiper-pagination-bullet
    opacity: 1
    background-color: #D5D6DA
  .swiper-pagination-bullet-active
    background-color: #fff
  &__container
    position: relative
    z-index: 1

.slider-info
  padding-top: 130px
  max-width: 430px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin-bottom: 168px
  @media all and (max-width: 991.9px)
    padding-top: 46px
  @media all and (max-width: 767.9px)
    padding-top: 30px
  &__logo
    width: 294px
    margin-bottom: 24px
    @media all and (max-width: 1139.9px)
      margin-bottom: 33px
    @media all and (max-width: 991.9px)
      margin-bottom: 24px
    @media all and (max-width: 767.9px)
      width: 200px
      margin-bottom: 16px
  &__logo-new
    width: 425px
    margin-bottom: 45px
    @media all and (max-width: 1139.9px)
      margin-bottom: 58px
    @media all and (max-width: 991.9px)
      margin-bottom: 25px
    @media all and (max-width: 767.9px)
      width: 270px
      margin-bottom: 23px
  &__p
    text-align: center
    color: #181818
    font-family: 'Open Sans', sans-serif
    font-size: 17px
    font-style: italic
    font-weight: 500
    margin-bottom: 26px
    @media all and (max-width: 1139.9px)
      font-size: 19px
    @media all and (max-width: 767.9px)
      margin-bottom: 13px
      //letter-spacing: -0.8px
  &__text
    //max-width: 340px
    width: 100%
    color: #181818
    font-family: 'Open Sans', sans-serif
    font-size: 14px
    font-style: italic
    font-weight: 700
    line-height: 27px
    text-transform: uppercase
    @media all and (max-width: 1139.9px)
      font-size: 15px
    a
      color: #003595
  @media all and (max-width: 991.9px)

    max-width: 100%
.slider2
  .page-main-banner
    @media all and (max-width: 1139.9px)
      object-position: 25% 50%
    @media all and (max-width: 991.9px)
      object-position: inherit
.slider-about
  max-height: 503px
  @media all and (max-width: 991.9px)
    margin-bottom: 40px
  @media all and (max-width: 575.9px)
    margin-bottom: 15px
  &__slide
    height: 470px
    position: relative
    border-radius: 10px
    overflow: hidden
    img
      width: 100%
      object-fit: cover
      position: absolute
      height: 100%
  &__info
    border-radius: 0 0 10px 10px
    padding: 14px 5px
    position: absolute
    bottom: 0px
    left: 0
    width: 100%
    background: rgba(53, 55, 63, 0.7)
    color: #ffffff
    text-align: center
    font: 700 18px/130% 'Open Sans', sans-serif
    font-style: italic
