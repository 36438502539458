$p25: 28px
$p30: 10px
$p15: 15px
.product-page-banner
  height: 195px
  position: relative
  .title-bg
    width: 100%
    height: 100%
  &__container
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
  .main-banner__title-block
    padding: 0
    margin: 0
    z-index: 1
    position: relative
  &__title
    font: 800 40px/54px 'Open Sans', sans-serif
    margin: 0
    @media (max-width: 575px)
      font: 800 25px/30px "Open Sans", sans-serif

.product-block
  &__title
    color: #0F1213
    font: 700 30px/130% 'Open Sans', sans-serif
    @media (max-width: 575px)
      font: 700 22px/130% 'Open Sans', sans-serif
  &__title-new
    background-color: #E2E5ED
    border-radius: 10px 10px 0 0
    padding: 23px 30px 13px 30px
    margin-bottom: 0

.cart-block-area
  padding: 30px 0
.product
  display: flex
  //min-height: 370px
  border: 1px solid #D8D8D8
  border-radius: 3px
  &__row
    width: 100%
    //background-color: #fff
  &__col
    padding: 0
    border-right: 1px solid #D8D8D8
  &__col:last-child
    border-right: 0
  &__col-info
    width: 477px
    @media all and (max-width: 1199.8px)
      width: inherit
  &__col-image
    @media all and (max-width: 1199.8px)
      width: inherit
      border-right: none
  &__col-add
    width: auto
    flex: 1
  &__image-block
    cursor: pointer
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    padding: 30px
    position: relative
    @media all and (max-width: 1199.8px)
      padding: 15px
    .product-image
      width: 100%
      flex: 1 1 200px
      max-width: 200px
      //height: auto
      object-fit: cover

  &__info-block
    padding-top: 16px
    padding-bottom: 5px
    padding-right: 10px
  &__cart-block
    padding: 16px 10px 30px 10px
.product-info
  &__title
    color: #E30613
    padding-left: $p25
    position: relative
    font: 600 21px/130% 'Open Sans', sans-serif
    margin-bottom: 19px
    @media all and (max-width: 991.8px)
      padding-left: $p15
    //&:before
    //  position: absolute
    //  content: ''
    //  left: 0
    //  top: -5px
    //  height: 35px
    //  background-color: #E30613
    //  border-radius: 5px
    //  width: 5px
  &__title-new
    color: #00408D
    padding-top: 4px
    padding-bottom: 4px
    background-color: #F4F4F4
  &__params
    display: flex
    position: relative
    margin-bottom: 11px
    flex: 1 1 auto
    margin-left: $p25
    margin-right: $p25
    @media all and (max-width: 991.8px)
      margin-left: $p15
      margin-right: $p15
    @media all and (max-width: 575.8px)
      align-items: center
    & > *
      flex: 0 1 50%
    &:before
      content: ''
      border-bottom: 1px dashed #6D6F7C
      position: absolute
      width: 50%
      left: 0
      top: 13px
      @media all and (max-width: 575.8px)
        top: 50%
    .name-params, .props-params
      z-index: 2
      position: relative
      span, strong
        white-space: pre-wrap
        background-color: #fff
        color: #272727
    .name-params
      span
        padding-right: 10px
        font: 600 18px/23px 'Open Sans', sans-serif
    .props-params
      //top: 2px
      @media all and (max-width: 575.8px)
        top: 0
      span
        display: block
        padding-left: 10px
        font: 400 15px/23px 'Open Sans', sans-serif
      strong
        padding-left: 10px
        color: #076CB5
        font: 700 21px/23px 'Open Sans', sans-serif
        font-style: italic
  &__stock
    align-items: center
    display: flex
    justify-content: center
    margin-top: 20px
    span
      margin: 0 3px
      color: #010202
      font: 400 16px/130% 'Open Sans', sans-serif

.zoom
  width: 21px
  height: 21px
  position: absolute
  bottom: $p30
  right: $p30

.product-info-card
  .product-info__title
    background-color: #F4F4F4
    color: #01509F
    text-align: center
    padding: 4px 0
    &:before
      display: none
  &__params
    margin-left: $p30
    margin-right: $p30
    margin-bottom: $p30
    display: flex
    justify-content: space-between
    position: relative
    @media all and (max-width: 991.8px)
      margin-left: $p15
      margin-right: $p15
    &:before
      width: 100%
    & > *
      flex: initial
    .name-params
      span
        color: #272727
        font-weight: 600
    .props-params
      display: inline-block
  //justify-content: right

  &__buttons
    margin-left: $p30
    margin-right: $p30
    display: flex
    margin-top: 22px
    flex-direction: column
    @media all and (max-width: 991.8px)
      margin-left: $p15
      margin-right: $p15
  .button--gray,
  .button--blue,
  .button--red
    min-height: 36px
    margin-bottom: 20px
    span
      font: 700 17px/130% 'Open Sans', sans-serif

.product-info-card__params .props-params
  span, .props-params-price
    color: #010202
    font: 700 21px/23px 'Open Sans', sans-serif
    &--red
      color: #E30613 !important
    b
      color: #6D6F7C
      font-weight: 400

.props-params-count
  &__area
    padding-left: 10px
    background-color: #fff
  .count
    right: -2px

@media all and (max-width: 991.8px)
  .product-info__params .name-params span
    font: 700 16px/20px 'Open Sans', sans-serif
  .product-info__params .props-params span
    font: 400 14px/20px 'Open Sans', sans-serif
    display: inline-block
  .product__col
    border: none
  .product-info__params:before
    width: 100%
  .props-params
    text-align: right
