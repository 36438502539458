button
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none
  user-select: none
  touch-action: manipulation

.button
  display: flex
  align-items: center
  justify-content: center
  background-color: transparent
  outline: none
  border: none
  padding: 5px
  margin: 0
  transition: 0.3s ease-in-out all
  &:disabled
    cursor: not-allowed
    background-color: #EEF0F5
    &:active, &:hover
      background-color: #EEF0F5
  &--skew
    border-radius: 3px
    transform: skewX(-15deg)
    span
      transition: 0.3s ease-in-out all
      white-space: nowrap
      text-transform: uppercase
      transform: skewX(15deg)
  &--red
    border-radius: 50px
    background-color: #E86200
    span
      transition: 0.3s ease-in-out all
      color: #fff
    &:focus
      background-color: #D45308
    &:active, &:hover
      background-color: #D45308
  &--gray
    border-radius: 50px
    background-color: #6D6F7C
    span
      transition: 0.3s ease-in-out all
      color: #fff
    &:active, &:hover, &:focus, &:focus-visible
      background-color: #616370
  &--blue
    background: #01509F
    border-radius: 20px
    span
      transition: 0.3s ease-in-out all
      color: #fff
    &:focus
      background-color: #E55A08
    &:active, &:hover
      background-color: #E55A08
  &--white
    border-radius: 50px
    background: #FFFFFF
    span
      transition: 0.3s ease-in-out all
      color: #272727
    &:focus
      background-color: #FFFFFF
    &:active, &:hover
      background-color: #E55A08
      span
        transition: 0.3s ease-in-out all
        color: #fff
