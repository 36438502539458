.error-page
  position: relative
  min-height: 660px
  padding-top: 38px
  padding-bottom: 100px
  &__container
    position: relative
    display: flex
    flex-direction: column
    align-items: center
  &__bg
    top: 0
    left: 0
    right: 0
    bottom: 0
    position: absolute
    width: 100%
    height: 100%
    object-fit: cover
    object-position: center
  &__logo
    //min-width: 320px
    max-width: 100%
    margin-bottom: 69px
  h2
    text-transform: inherit
    margin-bottom: 36px
    color: #fff
    font: 700 26px/26px "Open Sans", sans-serif
  &__code
    color: #fff
    font: 700 220px/220px "Open Sans", sans-serif
    margin-bottom: 10px
  &__info
    color: #FFFFFF
    font: 400 20px/26px "Open Sans", sans-serif
    margin-bottom: 45px
    text-align: center
  &__button-white
    max-width: 290px
    width: 100%
    color: #1E2225
    min-height: 38px
    font: 700 15px/20px "Open Sans", sans-serif
    text-transform: uppercase
